// import Image from "../assets/logo.png";

import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { axiosAuth } from "../utils/axiosInterceptor";



const Header = () => {
  const token = Cookies.get('pawloyalty_token');
  const role = Cookies.get('pawloyalty_role');
  
  return (
    <header>
      <section className="text-gray-100 bg-gradient-to-tr from-[#057D4B] via-[#09AA66] to-[#31C787] px-10 min-h-14 flex flex-wrap content-center justify-center">
        
        
        <div id="Logo">
          <Link to={token ? (role === 'ROLE_ADMINISTRATOR' ? "/admin" : "/client") : "/"}>
            <img src="/logo.png" alt="logo" className="scale-20 w-14" />
          </Link>
        </div>


      </section>

      <div className="bg-gradient-to-r from-[#057D4B] to-[#0BAB67]">
        <div className="h-[25px] rounded-t-[100px] bg-background"></div>
      </div>
    </header>
  );
}

export default Header;
