import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { axiosAuth } from '../../../utils/axiosInterceptor';
import AutoComplete from '../../../components/AutoComplete';
import { Specie } from '../../../utils/interfaces';

export default function AddBrand() {
  const navigate = useNavigate();

  const [species, setSpecies] = useState([]);

  // Get all species
  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + '/species')
      .then((response) => {
        setSpecies(response.data.data);
      });
  }, []);

  const [form, setForm] = useState<{
    name: string;
    species?: Specie;
  }>({
    name: '',
  });

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setForm({
      name: e.target.value,
    });
  }

  // Submit form
  function onSubmit() {
    axiosAuth
      .post(process.env.REACT_APP_BACKEND_URL + '/brands/', {
        name: form.name,
        species_id: form.species?.id,
      })
      .catch((error) => {
        console.log(error);
      });

    navigate('/admin/brands');
  }

  return (
    <div className="flex flex-col gap-8">
      <h1>Merk Toevoegen</h1>

      <div className="flex flex-col w-96">
        <label htmlFor="name">Naam</label>
        <input
          type="text"
          name="name"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="name"
          value={form.name}
          onChange={onChange}
          placeholder="Naam"
        />

        <AutoComplete 
          placeholder={'Selecteer een diersoort'}
          data={species}
          onChange={(item: any) => {
            setForm({
              ...form,
              species: item,
            });
          }}
          value={form.species}
        />
      </div>
      <div className='flex flex-row'>
        <button
            onClick={() => navigate(-1)}
            className="bg-primary rounded-full px-4 py-2 text-sm text-white hover:bg-[#16B471] mr-3 flex items-center justify-center w-24"
        >
            <img className="h-[20px] w-[20px] min-w-[20px] mr-2" src="/back-square.svg" alt="go back" />
            Terug
        </button>
        <button
          onClick={onSubmit}
          className="px-4 py-2 text-sm text-white bg-primary rounded-full hover:bg-[#16B471]"
        >
          Opslaan
        </button>
      </div>
    </div>
  );
}
