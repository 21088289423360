import { useState, useEffect } from 'react';
import AutoComplete from '../../../components/AutoComplete';
import { Customer } from '../../../utils/interfaces';
import { useNavigate } from 'react-router-dom';
import { axiosAuth } from '../../../utils/axiosInterceptor';

export default function SearchClient() {
  const navigate = useNavigate();

  const [data, setData] = useState<Customer[]>([]);

  // Get all customers
  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + '/administrator/customers/all')
      .then((response) => {
        if (response.data.customers.length > 0) {
          setData(prevState => [
            ...prevState,
            // Map through all customers and return an object with the required properties
            ...response.data.customers.map((customer: any) => ({
              id: customer.id,
              firstname: customer.firstname,
              lastname: customer.lastname,
              email: customer.email,
              phone: customer.phone,
              // Return the full name of the customer
              get name() {
                return `${this.firstname} ${this.lastname}`;
              },
            }))
          ]);
        }
      })
      .catch((error) => {
        console.error('Error fetching customer data:', error);
      });
  }, []);

  const [value, setValue] = useState();

  // Set value to the selected customer and navigate to the profile page of the customer
  const onSelect = (item: any) => {
    setValue(typeof item === 'string' ? { name: item } : item);
    if (typeof item === 'string' || !item.id) return;
    navigate(`/admin/clients/profile/${item.id}`);
  };

  return (
    <div className="flex flex-col gap-8">
      <AutoComplete placeholder={"Zoek klant..."} data={data} onChange={(item: any) => onSelect(item)} value={value} />
    </div>
  );
}
