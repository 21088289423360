import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderMobile from '../../components/Header-mobile';
import MobileNavbar from '../../components/Mobile-Navigation';
import { useMediaQuery } from '@react-hook/media-query';

import Contact from '../../components/client/contactForm';
import OnzeWinkels from '../../components/client/OnzeWinkels';



const ContactScreen = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <>
        <div className="w-full">
          {isMobile ? <HeaderMobile /> : <Header />}
        </div>

        <div className='flex flex-col gap-16'>
            <Contact />

            <OnzeWinkels />
        </div>

        <div className="w-full">
          {isMobile ? <MobileNavbar /> : <Footer />}
          {isMobile && <div className='h-[75px]'></div>}
        </div>
    
    </>
  );
};

export default ContactScreen;