import { useState, useEffect } from 'react';
import { axiosAuth } from '../../../utils/axiosInterceptor';
import { Link } from 'react-router-dom';

export default function Species() {
  const [species, setSpecies] = useState([]);

  // Get all species
  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + '/species')
      .then((response) => {
        setSpecies(response.data.data);
      });
  }, []);

  // Delete species
  function deleteSpecies(id: number) {
    axiosAuth
      .delete(process.env.REACT_APP_BACKEND_URL + '/species/delete/' + id)
      .then((response) => {
        setSpecies(species.filter((species: any) => species.id !== id));
      });
  }

  return (
    <div className="flex flex-col">
      <div className="flex mb-5">
        <p className="text-black text-bold text-3xl mr-4">Diersoorten</p>
        <Link
          to="/admin/species/add"
          className="bg-primary rounded-full px-4 py-2 text-white hover:bg-[#16B471] mr-3"
        >
          Toevoegen
        </Link>
      </div>
      {species.length > 0 ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg min-w">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Diersoorten
                </th>
                <th scope="col" className="px-6 py-3">
                  Update
                </th>
                <th scope="col" className="px-6 py-3">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {species.map((species: any) => (
                <tr className="odd:bg-white even:bg-gray-50">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {species.name}
                  </th>
                  <td className="px-6 py-4">
                    <Link
                      to={'/admin/species/edit/' + species.id}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Update
                    </Link>
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => deleteSpecies(species.id)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
