import { Link } from "react-router-dom";

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
      <section className="text-gray-300 bg-gradient-to-tr from-[#057D4B] via-[#09AA66] to-[#31C787] p-10 flex justify-between">
        <div className="flex flex-wrap">
          <p>&copy; Pawhaven {currentYear}</p>
        </div>
        <div className="flex flex-wrap gap-x-2">
            <Link to="/cookie-beleid">Cookie-beleid</Link>
            <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
            <Link to="/PrivacyPolicy">Privacy-beleid</Link>
            <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
            <Link to="/AlgemeneVoorwaarden">Algemene Voorwaarden</Link>
        </div>
      </section>
    )
  }