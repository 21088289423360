import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderMobile from '../../components/Header-mobile';
import MobileNavbar from '../../components/Mobile-Navigation';
import { useMediaQuery } from '@react-hook/media-query';

const AlgemeneVoorwaarden = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <div className="w-full">
        {isMobile ? <HeaderMobile /> : <Header />}
      </div>
      <div className="py-16 px-32 flex-1 w-full bg-background">
        <div id="content" className="flex justify-center p-6">
          <div id="cookie-beleid" className="max-w-[820px]">
            <h1 className="text-2xl font-bold mb-4">Algemene Voorwaarden</h1>
            <p className="mb-4">
              Deze voorwaarden en condities zijn voor het laatst geüpdatet op
              januari 24, 2024.
            </p>

            <h2 className="text-lg font-bold mb-2">1. Inleiding</h2>
            <p className="mb-4">
              Deze voorwaarden en condities zijn van toepassing op deze site en
              op de transacties die betrekking hebben op onze producten en
              diensten.
            </p>
            <p className="mb-4">
              Je kunt gebonden zijn aan bijkomende contracten in verband met je
              relatie met ons of met producten of diensten die je van ons
              ontvangt. Indien bepalingen van de aanvullende overeenkomsten in
              strijd zijn met bepalingen van deze voorwaarden, zullen de
              bepalingen van die aanvullende overeenkomsten voorrang hebben.
            </p>

            <h2 className="text-lg font-bold mb-2">2. Bindend</h2>
            <p className="mb-4">
              Door je te registreren bij, toegang te verkrijgen tot of
              anderszins gebruik te maken van deze site, stem je er mee in
              gebonden te zijn aan de hieronder uiteengezette voorwaarden en
              condities.
            </p>
            <p className="mb-4">
              Het gebruik van deze site impliceert dat je kennis hebt genomen
              van deze voorwaarden en condities en deze aanvaardt. In sommige
              specifieke gevallen kunnen wij je ook vragen uitdrukkelijk in te
              stemmen.
            </p>

            <h2 className="text-lg font-bold mb-2">
              3. Intellectueel eigendom
            </h2>
            <p className="mb-4">
              Wij of onze licentiegevers bezitten en beheren alle auteursrechten
              en andere intellectuele eigendomsrechten op de site en de
              gegevens, informatie en andere bronnen die worden weergegeven door
              of toegankelijk zijn op de site.
            </p>
            <p className="mb-4">
              Alle rechten zijn voorbehouden, tenzij er bij specifieke inhoud
              iets anders is aangegeven. Je wordt geen licentie of enig ander
              recht verleend op grond van auteursrechten, handelsmerken,
              octrooien of andere intellectuele eigendomsrechten.
            </p>

            <h2 className="text-lg font-bold mb-2">4. Eigendom van derden</h2>
            <p className="mb-4">
              Onze site kan hyperlinks of andere verwijzingen naar sites van
              andere partijen bevatten. Wij controleren of beoordelen de inhoud
              van sites van andere partijen waarnaar vanaf deze site wordt
              gelinkt niet.
            </p>
            <p className="mb-4">
              Producten of diensten die door andere sites worden aangeboden,
              zijn onderworpen aan de toepasselijke voorwaarden en condities van
              die derden. De meningen of het materiaal op deze sites worden niet
              noodzakelijkerwijs door ons gedeeld of onderschreven.
            </p>
            <p className="mb-4">
              Wij zijn niet verantwoordelijk voor de privacy praktijken of
              inhoud van deze sites. Je draagt alle risico’s verbonden aan het
              gebruik van deze sites en alle verwante diensten van derden. Wij
              aanvaarden geen enkele verantwoordelijkheid voor verlies of
              schade, op welke wijze dan ook ontstaan, die het gevolg is van
              door jou zelf verstrekte persoonsgegevens aan derden.
            </p>

            <h2 className="text-lg font-bold mb-2">
              5. Verantwoordelijk gebruik
            </h2>
            <p className="mb-4">
              Door onze site te bezoeken, stem je ermee in deze alleen te zullen
              gebruiken voor de doeleinden waarvoor deze bestemd is en zoals
              toegestaan door deze voorwaarden, eventuele aanvullende contracten
              met ons, en door toepasselijke wetten, voorschriften en algemeen
              aanvaarde online praktijken en richtlijnen.
            </p>
            <p className="mb-4">
              Je mag onze site of diensten niet gebruiken voor het gebruiken,
              publiceren of verspreiden van materiaal dat bestaat uit (of
              gekoppeld is aan) schadelijke computersoftware; gegevens van onze
              site niet gebruiken voor direct-marketingactiviteiten en geen
              systematische of geautomatiseerde gegevensverzamelingsactiviteiten
              uitvoeren op of in verband met onze site.
            </p>
            <p className="mb-4">
              Het is ten strengste verboden activiteiten te ontplooien die
              schade toebrengen of kunnen toebrengen aan de site of die de
              werking, de beschikbaarheid of de toegankelijkheid van de site
              belemmeren.
            </p>

            <h2 className="text-lg font-bold mb-2">6. Indienen van ideeën</h2>
            <p className="mb-4">
              Verstuur aan ons geen ideeën, uitvindingen, auteursrechtelijk
              beschermde werken, of andere informatie die kan worden beschouwd
              als je eigen intellectuele eigendom, tenzij we eerst een
              overeenkomst hebben ondertekend betreffende het intellectuele
              eigendom of over de geheimhouding.
            </p>
            <p className="mb-4">
              Als je de inhoud aan ons bekendmaakt zonder een dergelijke
              schriftelijke overeenkomst, verleen je aan ons een wereldwijde,
              onherroepelijke, niet-exclusieve, royaltyvrije licentie om de
              inhoud te gebruiken, te reproduceren, op te slaan, aan te passen,
              te publiceren, te vertalen en te distribueren in alle bestaande of
              toekomstige mediavormen.
            </p>

            <h2 className="text-lg font-bold mb-2">
              7. Beëindiging van gebruik
            </h2>
            <p className="mb-4">
              Wij kunnen, naar eigen inzicht, te allen tijde de toegang tot de
              site of enige dienst daarop, tijdelijk of permanent, wijzigen of
              stopzetten.
            </p>
            <p className="mb-4">
              Je stemt ermee in dat wij niet aansprakelijk zijn jegens jou of
              een derde partij voor een dergelijke wijziging, opschorting of
              stopzetting van je toegang tot of gebruik van de site of enige
              inhoud die je mogelijk hebt gedeeld op de site.
            </p>
            <p className="mb-4">
              Je hebt geen recht op enige compensatie of andere betaling, zelfs
              niet indien bepaalde functies, instellingen en/of inhoud die je
              hebt bijgedragen of waarop je bent gaan vertrouwen, permanent
              verloren gaan. Je mag geen toegangsbeperkende maatregelen op onze
              site omzeilen of proberen te omzeilen.
            </p>

            <h2 className="text-lg font-bold mb-2">
              8. Garanties en aansprakelijkheid
            </h2>
            <p className="mb-4">
              Niets in dit artikel zal een wettelijk voorgeschreven garantie
              beperken of uitsluiten waarvan het onrechtmatig zou zijn om deze
              te beperken of uit te sluiten.
            </p>
            <p className="mb-4">
              Deze site en alle inhoud op de site worden aangeboden op een
              "zoals het is" en "zoals beschikbaar" basis en kunnen
              onnauwkeurigheden of typografische fouten bevatten.
            </p>
            <p className="mb-4">
              Wij verwerpen uitdrukkelijk alle garanties van welke aard dan ook,
              hetzij uitdrukkelijk of impliciet, met betrekking tot de
              beschikbaarheid, nauwkeurigheid of volledigheid van de inhoud.
            </p>
            <p className="mb-4">Wij garanderen niet dat:</p>
            <ul className="list-disc ml-8">
              <li>deze site of onze inhoud aan je eisen zal voldoen;</li>
              <li>
                deze site beschikbaar zal zijn op een ononderbroken, tijdige,
                veilige of foutloze basis.
              </li>
            </ul>
            <p className="mb-4">
              Niets op deze site is bedoeld om als juridisch, financieel of
              medisch advies van welke aard beschouwd te kunnen worden. Indien
              je advies nodig hebt dien je een deskundige te raadplegen.
            </p>
            <p className="mb-4">
              De volgende bepalingen van dit artikel zijn van toepassing voor
              zover maximaal toegestaan door de toepasselijke wetgeving en
              zullen onze aansprakelijkheid niet beperken of uitsluiten in
              gevallen dat dit onwettig of verboden zou zijn.
            </p>
            <p className="mb-4">
              In geen geval zijn wij aansprakelijk voor directe of indirecte
              schade (met inbegrip van winstderving of verlies van inkomsten,
              verlies of beschadiging van gegevens, software of databanken,
              verlies van of schade aan eigendommen of gegevens) opgelopen door
              jou of een derde partij, voortvloeiend uit je toegang tot, of
              gebruik van onze site.
            </p>
            <p className="mb-4">
              Tenzij in een aanvullend contract uitdrukkelijk anders is bepaald,
              is onze maximale aansprakelijkheid ten opzichte van jou voor alle
              schade die voortvloeit uit of verband houdt met de site of
              producten en diensten die via de site op de markt worden gebracht
              of verkocht, ongeacht de oorzaak waaruit die aansprakelijkheid zou
              voortvloeien, beperkt tot de totale prijs die je aan ons hebt
              betaald om dergelijke producten of diensten te kopen of om de site
              te gebruiken. Een dergelijke beperking is van toepassing op al je
              vorderingen van elke soort en aard.
            </p>

            <h2 className="text-lg font-bold mb-2">9. Privacy</h2>
            <p className="mb-4">
              Om toegang te krijgen tot onze site en/of diensten, kun je
              gevraagd worden bepaalde informatie over jezelf te verstrekken als
              onderdeel van het registratieproces.
            </p>
            <p className="mb-4">
              Je stemt ermee in dat alle informatie die je verstrekt altijd
              accuraat, correct en actueel is.
            </p>
            <p className="mb-4">
              We hebben beleid ontwikkeld om alle mogelijke zorgen over privacy
              te ondervangen. Zie voor meer informatie onze{' '}
              <a href="/PrivacyPolicy">Privacyverklaring</a> en ons{' '}
              <a href="/cookie-beleid">Cookiebeleid</a>.
            </p>

            <h2 className="text-lg font-bold mb-2">
              10. Exportbeperkingen / Naleving van wetgeving
            </h2>
            <p className="mb-4">
              Toegang tot de site is verboden voor bezoekers vanuit gebieden of
              landen waar de inhoud of de aankoop van de producten of diensten
              die op de site worden verkocht illegaal is.
            </p>
            <p className="mb-4">
              Je mag deze site niet gebruiken in strijd met de exportwetten en
              -voorschriften van Nederland.
            </p>

            <h2 className="text-lg font-bold mb-2">11. Affiliate marketing</h2>
            <p className="mb-4">
              Via deze site kunnen wij aan partner marketing doen waarbij wij
              een percentage van of een commissie ontvangen op de verkoop van
              diensten of producten op of via deze site.
            </p>
            <p className="mb-4">
              Wij kunnen ook sponsoring of andere vormen van reclamevergoeding
              van bedrijven aanvaarden. Dit document is bedoeld om te voldoen
              aan de wettelijke voorschriften inzake marketing en reclame die
              van toepassing kunnen zijn, zoals de voorschriften van de
              Amerikaanse Federal Trade Commission.
            </p>

            <h2 className="text-lg font-bold mb-2">12. Opdracht</h2>
            <p className="mb-4">
              Je kunt geen van je rechten en/of verplichtingen onder deze
              voorwaarden en condities geheel of gedeeltelijk toewijzen,
              overdragen of uitbesteden aan een derde partij zonder onze
              voorafgaande schriftelijke toestemming. Elke vermeende overdracht
              die in strijd is met deze afdeling, is nietig en ongeldig.
            </p>

            <h2 className="text-lg font-bold mb-2">
              13. Inbreuken op deze voorwaarden en condities
            </h2>
            <p className="mb-4">
              Onverminderd onze andere rechten op grond van deze voorwaarden,
              kunnen wij, indien je deze voorwaarden op enige wijze schendt, de
              maatregelen nemen die wij passend achten om de schending aan te
              pakken, waaronder het tijdelijk of permanent opschorten van je
              toegang tot de site, contact opnemen met je internetprovider met
              het verzoek je toegang tot de site te blokkeren, en/of door
              juridische stappen tegen je ondernemen.
            </p>

            <h2 className="text-lg font-bold mb-2">14. Vrijwaring</h2>
            <p className="mb-4">
              Je gaat ermee akkoord ons te vrijwaren, te verdedigen en
              schadeloos te stellen van en tegen alle claims,
              aansprakelijkheden, schade, verliezen en onkosten, met betrekking
              tot jouw schending van deze voorwaarden en toepasselijke wetten,
              met inbegrip van intellectuele eigendomsrechten en privacyrechten.
              Je zult ons onmiddellijk een vergoeding geven voor onze schade,
              verliezen, kosten en uitgaven die verband houden met of
              voortvloeien uit dergelijke vorderingen.
            </p>

            <h2 className="text-lg font-bold mb-2">15. Ontheffing</h2>
            <p className="mb-4">
              Het niet afdwingen van een van de bepalingen in deze voorwaarden
              en in enig ander document, of het niet uitoefenen van een
              mogelijkheid tot beëindiging, kan niet worden opgevat als een
              verklaring van afstand of stilzwijgende instemming, en zal geen
              invloed hebben op de geldigheid van deze voorwaarden of enig
              andere overeenkomst of een deel daarvan, of het recht om daarna
              alle bepalingen af te dwingen.
            </p>

            <h2 className="text-lg font-bold mb-2">16. Taal</h2>
            <p className="mb-4">
              Deze voorwaarden en condities zullen uitsluitend worden
              geïnterpreteerd en uitgelegd in het Nederlands. Alle mededelingen
              en correspondentie worden uitsluitend in die taal gevoerd.
            </p>

            <h2 className="text-lg font-bold mb-2">17. Gehele overeenkomst</h2>
            <p className="mb-4">
              Deze voorwaarden en condities, samen met onze privacyverklaring en
              cookiebeleid, vormen de volledige overeenkomst tussen jou en
              PawLoyalty met betrekking tot je gebruik van deze site.
            </p>

            <h2 className="text-lg font-bold mb-2">
              18. Updaten van deze voorwaarden en condities
            </h2>
            <p className="mb-4">
              Wij kunnen deze voorwaarden en condities van tijd tot tijd
              updaten. Je bent verplicht om deze voorwaarden en condities
              regelmatig te controleren op veranderingen of updates. De datum
              aan het begin van deze voorwaarden en condities is de laatste
              revisiedatum. Wijzigingen in deze voorwaarden en condities worden
              van kracht zodra dergelijke wijzigingen op deze site zijn
              geplaatst. Je voortdurende gebruik van deze site, na het
              aanbrengen van wijzigingen of updates, zal worden beschouwd als
              kennisgeving van je aanvaarding van de naleving van en
              gebondenheid aan deze voorwaarden.
            </p>

            <h2 className="text-lg font-bold mb-2">
              19. Rechtskeuze en rechterlijke bevoegdheid
            </h2>
            <p className="mb-4">
              Op deze voorwaarden en condities is het recht van toepassing van
              Nederland. Alle geschillen met betrekking tot deze algemene
              voorwaarden zijn onderworpen aan de bevoegdheid van de rechtbank
              in Nederland. Indien enig deel of enige bepaling van deze
              voorwaarden en condities door een rechtbank of andere autoriteit
              ongeldig en/of niet-afdwingbaar wordt bevonden, zal dat deel of
              die bepaling worden gewijzigd, verwijderd en/of afgedwongen voor
              zover dat maximaal is toegestaan om uitvoering te geven aan de
              intentie van deze voorwaarden. De overige bepalingen blijven
              onverlet.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full">
        {isMobile ? <MobileNavbar /> : <Footer />}
        {isMobile && <div className='h-[75px]'></div>}
      </div>
    </>
  );
};

export default AlgemeneVoorwaarden;
