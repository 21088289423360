import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link } from 'react-router-dom';


interface UserData {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

const Profile = () => {
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const user_id = Cookies.get('pawloyalty_id');
      const user_token = Cookies.get('pawloyalty_token');

      if (user_id && user_token) {
        try {
          const response = await axios.get(`http://127.0.0.1:8000/api/administrators/${user_id}`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${user_token}`,
            },
          });

          if (response.status === 200) {
            setUserData(response.data.administrator);
            console.log(response.data.administrator);
          } else {
            alert(response.statusText);
          }
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      }
    };

    fetchData();
  }, []);

    const role = Cookies.get('pawloyalty_role');

    if (role === 'ROLE_CUSTOMER') {
        window.location.href = '/profile';
        return null;
    } else {   
      return (
        <div className="flex flex-col">
          <p className="text-black text-bold text-3xl pb-5">Profiel</p>

          {/* die andere lijn */}
          <div className="inline-flex items-center justify-center w-full">
            <hr className="w-full h-px my-8 bg-gray-200 border-0" />
            <span className="absolute px-3 text-2xl font-medium text-gray-500 -translate-x-1/2 -translate-y-1 bg-background left-1/2">
              Mijn gegevens
            </span>
          </div>

          {userData ? (
              
            <div className="mx-auto bg-white shadow-lg rounded-lg w-full">
                <div className="p-3 overflow-auto">
                    <div className="flex flex-col md:grid md:grid-cols-2 gap-4 mt-2">
                        <div className="h-8 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                          <span className='mr-2 text-gray-500'>Voornaam:</span> {userData.firstname}
                        </div>
                        <div className="h-8 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                          <span className='mr-2 text-gray-500'>Achternaam:</span> {userData.lastname}
                        </div>
                        <div className="h-8 col-span-2 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                          <span className='mr-2 text-gray-500'>Email:</span> {userData.email}
                        </div>
                        <div className="h-8 col-span-2 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                          <span className='mr-2 text-gray-500'>Telefoon:</span> {userData.phone}
                        </div>
                    </div>
                </div>
            </div>

          ) : (
            <p>Loading...</p>
          )}

          <div className='mt-6'>
            <Link
              to="/admin/profile/edit"
              className="bg-primary rounded-full px-4 py-2 text-white hover:bg-[#16B471]"
            >
              Bewerken
            </Link>
          </div>
        </div>
      );
  }
}
export default Profile;