import axios from 'axios';
import Cookies from 'js-cookie';

// create new axios instance to intercept requests and responses
export const axiosAuth = axios.create();

axiosAuth.interceptors.request.use(
  (config) => {
    config.headers['Accept'] = 'application/json';

    // get token from cookies and add to the request
    const token = Cookies.get('pawloyalty_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/';
    }
  }
);