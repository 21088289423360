import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { axiosAuth } from '../../../utils/axiosInterceptor';

export default function AddSpecies() {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    name: '',
  });

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setForm({
      name: e.target.value,
    });
  }

  // Submit form
  function onSubmit() {
    axiosAuth
      .post(process.env.REACT_APP_BACKEND_URL + '/species/', {
        name: form.name,
      }).catch((error) => {
        console.log(error);
      });

    navigate('/admin/species');
  }

  return (
    <div className="flex flex-col gap-8">
      <h1>Diersoort Toevoegen</h1>

      <div className="flex flex-col w-96">
        <label htmlFor="name">Naam</label>
        <input
          type="text"
          name="name"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="name"
          value={form.name}
          onChange={onChange}
          placeholder="Naam"
        />
      </div>
      <div className='flex flex-row'>
      <button
            onClick={() => navigate(-1)}
            className="bg-primary rounded-full px-4 py-2 text-sm text-white hover:bg-[#16B471] mr-3 flex items-center justify-center w-24"
        >
            <img className="h-[20px] w-[20px] min-w-[20px] mr-2" src="/back-square.svg" alt="go back" />
            Terug
        </button>
        <button
          onClick={onSubmit}
          className="px-4 py-2 text-sm text-white bg-primary rounded-full hover:bg-[#16B471]"
        >
          Opslaan
        </button>
      </div>
    </div>
  );
}
