import React from 'react';
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';



const WelcomeScreen = () => {
    const token = Cookies.get('pawloyalty_token');
    const role = Cookies.get('pawloyalty_role');

    if (token !== undefined) {
        if (role === 'ROLE_CUSTOMER') {
            window.location.href = '/client';
        } else if (role === 'ROLE_ADMINISTRATOR') {
            window.location.href = '/admin';
        }
        return null;
    } else {    
        return (
            <div className="bg-gradient-to-tr from-[#057D4B] via-[#09AA66] to-[#31C787] min-h-screen flex justify-center">
                <div className="min-h-screen flex justify-center bg-no-repeat bg-left-bottom min-w-[100vw]" style={{ backgroundImage: 'url(/welkom-linksonder.svg)' }}>
                <div className="min-h-screen flex justify-center bg-no-repeat bg-right-top min-w-[100vw]" style={{ backgroundImage: 'url(/welkom-rechtsboven.svg)' }}>
                    <div className="flex items-center justify-center flex-col px-10 max-w-[700px] w-full mt-9">
                        <div className="text-white w-full">
                            <img src="/logo.png" className="max-w-[160px]"></img>

                            <div className="my-8">
                                <h1 className="text-4xl font-semibold">Welkom</h1>
                                <p className="max-w-[410px] text-xl">Ontgrendel een wereld van exclusieve voordelen en ontdek een wereld van voordelen binnen handbereik met</p>
                                <b className="max-w-[410px] text-xl">Paw Loyalty</b>
                            </div>

                            <div>
                                <Link to="/login">
                                    <button className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-primary focus:outline-none bg-white rounded-3xl border border-gray-200 hover:bg-gray-100 hover:gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200 w-full">Log in op uw account</button>
                                </Link>

                                <Link to="/register">
                                    <button className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-white focus:outline-none rounded-3xl border border-white hover:gray-900 hover:bg-[#16B471] focus:z-10 focus:ring-4 focus:ring-gray-200 w-full">Registeren</button>
                                </Link>
                            </div>

                            <div className='mt-9'>
                                <Link to="/admin/login">
                                    <button className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-state-300 focus:outline-none rounded-3xl border border-slate-300 hover:gray-900 hover:bg-[#16B471] focus:z-10 focus:ring-4 focus:ring-gray-200 w-full">Admin panel</button>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
                </div>
            </div>
        );
    };
};

export default WelcomeScreen;