// import Image from "../assets/logo.png";

import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Cookies from 'js-cookie';



const Header = () => {
  const token = Cookies.get('pawloyalty_token');
  const role = Cookies.get('pawloyalty_role');

  return (

    <div className="fixed z-50 w-[95%] h-16 max-w-lg -translate-x-1/2 bg-gradient-to-tr from-[#057D4B] via-[#09AA66] to-[#31C787] border border-[#09AA66] rounded-full bottom-4 left-1/2">

            
        {(() => {
          if (token !== undefined && role === 'ROLE_CUSTOMER' || role === 'ROLE_ADMINISTRATOR') {
            if (role === 'ROLE_CUSTOMER') {
              return (
                <>
                <div className="grid h-full max-w-lg grid-cols-4 mx-auto">
                    <Link to="/client" data-tooltip-target="tooltip-home" className="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-[#0b402a] group">
                        <svg className="w-5 h-5 mb-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            <span className="sr-only">Home</span>   
                        </Link>
                        <div id="tooltip-home" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Home
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <Link to="/pets" data-tooltip-target="tooltip-huisdieren" className="inline-flex flex-col items-center justify-center px-5 hover:bg-[#0b402a] group">
                            <img className="h-[30px] w-[30px] min-w-[30px]" src="/dog.svg" alt="dog" />
                            <span className="sr-only">Huisdieren</span>
                        </Link>
                        <div id="tooltip-huisdieren" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Huisdieren
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <Link to="/profile" data-tooltip-target="tooltip-profile" className="inline-flex flex-col items-center justify-center px-5 hover:bg-[#0b402a] group">
                        <svg className="w-5 h-5 mb-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                            </svg>
                            <span className="sr-only">Profile</span>
                        </Link>
                        <div id="tooltip-profile" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Profile
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <Link to="/navigation-more" data-tooltip-target="tooltip-more" className="inline-flex flex-col items-center justify-center px-5 rounded-e-full hover:bg-[#0b402a] group">
                        <img className="h-[30px] w-[30px] min-w-[30px]" src="/more-2.svg" alt="more" />
                            <span className="sr-only">More</span>
                        </Link>
                        <div id="tooltip-more" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            More
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                    </div>
                </>
              );
            } else if (role === 'ROLE_ADMINISTRATOR') {
              return (
                <>
                <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
                    <Link to="/admin" data-tooltip-target="tooltip-home" className="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-[#0b402a] group">
                        <svg className="w-5 h-5 mb-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            <span className="sr-only">Home</span>   
                        </Link>
                        <div id="tooltip-home" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Home
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <Link to="/admin/products" data-tooltip-target="tooltip-producten" className="inline-flex flex-col items-center justify-center px-5 hover:bg-[#0b402a] group">
                            <img className="h-[30px] w-[30px] min-w-[30px]" src="/products.svg" alt="producten" />
                            <span className="sr-only">Producten</span>
                        </Link>
                        <div id="tooltip-producten" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Producten
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <Link to="/admin/brands" data-tooltip-target="tooltip-merken" className="inline-flex flex-col items-center justify-center px-5 hover:bg-[#0b402a] group">
                            <img className="h-[30px] w-[30px] min-w-[30px]" src="/brands.svg" alt="merken" />
                            <span className="sr-only">Merken</span>
                        </Link>
                        <div id="tooltip-merken" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Merken
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>

                        <Link to="/admin/clients" data-tooltip-target="tooltip-clients" className="inline-flex flex-col items-center justify-center px-5 hover:bg-[#0b402a] group">
                            <img className="h-[30px] w-[30px] min-w-[30px]" src="/users.svg" alt="gebruikers" />
                            <span className="sr-only">Gebruikers</span>
                        </Link>
                        <div id="tooltip-clients" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Gebruikers
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>

                        <Link to="/navigation-more" data-tooltip-target="tooltip-more" className="inline-flex flex-col items-center justify-center px-5 rounded-e-full hover:bg-[#0b402a] group">
                        <img className="h-[30px] w-[30px] min-w-[30px]" src="/more-2.svg" alt="more" />
                            <span className="sr-only">More</span>
                        </Link>
                        <div id="tooltip-more" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            More
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                    </div>
                </>
              );
            }
          } else {
            return (
                <>
                <div className="grid h-full max-w-lg grid-cols-4 mx-auto">
                    <Link to="/" data-tooltip-target="tooltip-welkom" className="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-[#0b402a] group">
                        <img className="h-[30px] w-[30px] min-w-[30px]" src="/welcome.svg" alt="welcome" />
                        </Link>
                        <div id="tooltip-welkom" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Welkom
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <Link to="/login" data-tooltip-target="tooltip-login" className="inline-flex flex-col items-center justify-center px-5 hover:bg-[#0b402a] group">
                            <img className="h-[30px] w-[30px] min-w-[30px]" src="/login.svg" alt="login" />
                            <span className="sr-only">Login</span>
                        </Link>
                        <div id="tooltip-login" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Login
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <Link to="/register" data-tooltip-target="tooltip-register" className="inline-flex flex-col items-center justify-center px-5 hover:bg-[#0b402a] group">
                            <img className="h-[30px] w-[30px] min-w-[30px]" src="/register.svg" alt="register" />
                            <span className="sr-only">Register</span>
                        </Link>
                        <div id="tooltip-register" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Register
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <Link to="/contact" data-tooltip-target="tooltip-contact" className="inline-flex flex-col items-center justify-center px-5 rounded-e-full hover:bg-[#0b402a] group">
                            <img className="h-[30px] w-[30px] min-w-[30px]" src="/contact.svg" alt="contact" />
                            <span className="sr-only">Contact</span>
                        </Link>
                        <div id="tooltip-contact" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                            Contact
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                    </div>
                </>
            );
          }
        })()}
    </div>

  );
}

export default Header;
