import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import UserAmount from '../../components/admin/user-amount';
import { Link } from 'react-router-dom';




const getCurrentTimeOfDay = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 4 && currentHour < 12) {
        return 'morgen';
    } else if (currentHour >= 12 && currentHour < 18) {
        return 'middag';
    } else {
        return 'avond';
    }
};

const getCurrentDayAndDate = () => {
    const days = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
    const today = new Date();
    const day = days[today.getDay()];
    const date = today.getDate();
    const month = months[today.getMonth()];
    return `Het is vandaag ${day} ${date} ${month}.`;
};

const AdminHomeScreen = () => {
  return (
    <div className='flex flex-col gap-16'>
        <div>
            <h1 className="text-4xl font-semibold">Goede{getCurrentTimeOfDay()} administrator <span className='font-black'>{Cookies.get('pawloyalty_user_firstname')}</span></h1>
            <p>{getCurrentDayAndDate()}</p>
        </div>
        

        <div className='flex flex-row flex-wrap gap-8'>

            <div className="bg-white shadow-lg rounded-2xl py-5 px-10">
                <div className="justify-center flex flex-col">
                    <p className="text-black text-xl font-semibold pb-3">
                        Beheerpaneel
                    </p>
                    <Link to="./breeds/"
                        className="transition-all duration-200 hover:text-gray-500 hover:font-semibold py-1">
                        Rassen
                    </Link>
                    <Link to="./species/"
                        className="transition-all duration-200 hover:text-gray-500 hover:font-semibold py-1">
                        Diersoorten
                    </Link>
                    <Link to="./products/"
                        className="transition-all duration-200 hover:text-gray-500 hover:font-semibold py-1">
                        Producten
                    </Link>
                    <Link to="./brands/"
                        className="transition-all duration-200 hover:text-gray-500 hover:font-semibold py-1">
                        Merken
                    </Link>
                    <Link to="./clients"
                        className="transition-all duration-200 hover:text-gray-500 hover:font-semibold py-1">
                        Klanten
                    </Link>
                </div>
            </div>

            <UserAmount/>
    </div>
    </div>
  );
};

export default AdminHomeScreen;