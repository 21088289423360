import axios from 'axios';

import { useState, useEffect } from 'react';
import Pet from '../../../components/client/Pet';
import { Brand, Breed, Product, Specie } from '../../../utils/interfaces';
import { useNavigate } from 'react-router-dom';
import { axiosAuth } from '../../../utils/axiosInterceptor';
import Cookies from 'js-cookie';

export default function AddPet() {
  const navigate = useNavigate();

  // Get all species
  useEffect(() => {
    axiosAuth.get(process.env.REACT_APP_BACKEND_URL + "/brand-species/products").then((response) => {
      setSpecies(response.data.data);
    });
  }, []);

  const [species, setSpecies] = useState<Specie[]>([]);

  const [pets, setPets] = useState<
    {
      name?: string;
      species?: Specie;
      breed?: Breed;
      brand?: Brand;
      product?: Product;
      birthDate?: Date;
    }[]
  >([]);

  const [currentPet, setCurrentPet] = useState(0);
  
  // send the data to the parent component when the form changes
  const onFormChange = (
    form: {
      name?: string;
      species?: Specie;
      breed?: Breed;
      brand?: Brand;
      product?: Product;
      birthDate?: Date;
    },
    id: number
  ) => {
    const pet = pets[id];
    if (!pet) {
      setPets([...pets, form]);
    } else {
      setPets(pets.map((pet, index) => (index === id ? form : pet)));
    }
  };

  // add a new pet
  const handleAddPet = async () => {
    setCurrentPet(currentPet + 1);
  };

  // submit the form
  const onSubmit = async () => {
    const customer_id = parseInt(Cookies.get("pawloyalty_id") ?? "");

    if (!customer_id) return navigate('/login');

    if (pets.length === 0) return alert("Voeg minstens 1 huisdier toe");

    // validate the data
    const data = pets.map((pet) => ({
      name: pet.name ?? (() => {throw new Error("Name is required")}),
      species_id: pet.species?.id ?? (() => {throw new Error("Species is required")}),
      breed_id: pet.breed?.id,
      product_id: pet.product?.id,
      date_of_birth: pet.birthDate?.toISOString().split("T")[0],
      customer_id: customer_id,
    }));

    try {
      await axiosAuth.post(process.env.REACT_APP_BACKEND_URL + "/pets", data);
    } catch (error) {
      console.error(error);
      return alert("Er is iets misgegaan");
    }

    navigate('/pets');
  };

  if (!species) return <div>Loading...</div>;

  return (
    <div className="flex flex-col gap-8">
      <h1 className="text-2xl">Huisdieren</h1>

      <Pet
        species={species}
        petId={currentPet}
        data={pets[currentPet] || {}}
        onChange={onFormChange}
      />

      <div className="flex justify-between">
        <button
          disabled={!pets[currentPet]?.species?.id}
          onClick={onSubmit}
          className="border disabled:text-gray-500 disabled:border-gray-500 disabled:cursor-not-allowed border-primary text-primary px-5 py-2 rounded-full enabled:hover:bg-[#16B471] hover:text-white"
        >
          Huisdier toevoegen
        </button>
      </div>
    </div>
  );
}
