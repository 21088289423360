import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import HeaderMobile from '../components/Header-mobile';
import MobileNavbar from '../components/Mobile-Navigation';
import { useMediaQuery } from '@react-hook/media-query';

export default function Pagenotfound() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <div className="w-full">
        {isMobile ? <HeaderMobile /> : <Header />}
      </div>
      <div className="py-16 px-32 flex-1 w-full bg-background">
        <div id="PageContent" className="py-16 px-10 flex-1 w-full">
          <section className="text-gray-300 flex flex-wrap md:flex-nowrap justify-between">
            <div className="flex flex-col justify-center gap-6 md:w-1/2">
              <p className="text-black text-bold text-3xl font-bold">
                Oeps, deze pagina is er niet
              </p>
              <p className="text-black">
                Sorry daarvoor! Bezoek onze startpagina om te zien waar u heen
                moet.
              </p>

              <Link to="/">
                <button
                  type="button"
                  className="w-fit py-2 px-4 bg-[#057D4B] hover:bg-[#3e6d59] focus:ring-[#09AA66] focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                >
                  Breng me er naar toe
                </button>
              </Link>
            </div>

            <div className="flex justify-center md:w-1/2">
              <img src="/404.webp" alt='error' className="max-h-[350px]"></img>
            </div>
          </section>
        </div>
      </div>
      <div className="w-full">
        {isMobile ? <MobileNavbar /> : <Footer />}
        {isMobile && <div className='h-[75px]'></div>}
      </div>
    </>
  );
}
