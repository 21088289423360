import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { axiosAuth } from '../../../utils/axiosInterceptor';
import { CardData, Customer } from '../../../utils/interfaces';

const Profile = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState<Customer | null>(null);
  const [userCards, setUserCards] = useState<CardData[]>([]);
  const [cardCount, setCardCount] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      const user_id = Cookies.get('pawloyalty_id');
      const user_token = Cookies.get('pawloyalty_token');

      if (user_id && user_token) {
        try {
          // Fetch user data
          const userResponse = await axios.get(`http://127.0.0.1:8000/api/customers/${user_id}`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${user_token}`,
            },
          });

          if (userResponse.status === 200) {
            setUserData(userResponse.data.customer);
          } else {
            alert(userResponse.statusText);
          }

          // Fetch user cards data
          const cardsResponse = await axios.get(`http://127.0.0.1:8000/api/customers/${user_id}/cards`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${user_token}`,
            },
          });

          if (cardsResponse.status === 200) {
            setUserCards(cardsResponse.data.customer.cards);
            setCardCount(cardsResponse.data.customer.cards.length);
          } else {
            alert(cardsResponse.statusText);
          }
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      }
    };

    fetchData();
  }, []);

  async function deleteUser() {
    // make a call to the backend to anonymize the user
    await axiosAuth.put(process.env.REACT_APP_BACKEND_URL + `/customers/anonymize/${Cookies.get('pawloyalty_id')}`).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    });

    // remove the cookies
    Cookies.remove('pawloyalty_id');
    Cookies.remove('pawloyalty_token');
    Cookies.remove('pawloyalty_role');
    Cookies.remove('pawloyalty_user_firstname');
    Cookies.remove('pawloyalty_user_lastname');
    Cookies.remove('pawloyalty_user_email');


    // redirect to the homepage
    navigate('/');
  }

  const role = Cookies.get('pawloyalty_role');

  if (role === 'ROLE_ADMINISTRATOR') {
      window.location.href = '/admin/profile';
      return null;
  } else { 
  return (
    <div className="flex flex-col">
      <p className="text-black text-bold text-3xl pb-5">Profiel</p>
      <div className="border border-black rounded-lg p-5 text-black w-64">
        <p className="text-lg font-bold">{cardCount}</p>
        <p className="text-sm font-semibold">Klantenkaart(en) gekoppeld</p>
      </div>

      {/* die ene lijn */}
      <div className="inline-flex items-center justify-center w-full">
        <hr className="w-full h-px my-8 bg-gray-200 border-0" />
        <span className="absolute px-3 text-2xl font-medium text-gray-500 -translate-x-1/2 -translate-y-1 bg-background left-1/2">
          Aankoopgeschiedenis
        </span>
      </div>

      {/* Table */}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Datum
              </th>
              <th scope="col" className="px-6 py-3">
                Product
              </th>
              <th scope="col" className="px-6 py-3">
                Categorie
              </th>
              <th scope="col" className="px-6 py-3">
                Aantal
              </th>
              <th scope="col" className="px-6 py-3">
                Prijs
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="odd:bg-white even:bg-gray-50 border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                24/11/2023
              </th>
              <td className="px-6 py-4">Pedigree Hondenvoer</td>
              <td className="px-6 py-4">Hondenvoer</td>
              <td className="px-6 py-4">3</td>
              <td className="px-6 py-4">€9,90</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-50 border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                24/11/2023
              </th>
              <td className="px-6 py-4">Kattenborstel</td>
              <td className="px-6 py-4">Borstels</td>
              <td className="px-6 py-4">2</td>
              <td className="px-6 py-4">€12,50</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-50 border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                06/09/2023
              </th>
              <td className="px-6 py-4">Kattensnoepjes groente 50g</td>
              <td className="px-6 py-4">Dierensnacks</td>
              <td className="px-6 py-4">5</td>
              <td className="px-6 py-4">€35,50</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-50 border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                06/09/2023
              </th>
              <td className="px-6 py-4">Hondenbot Duopack</td>
              <td className="px-6 py-4">Kauwbotten</td>
              <td className="px-6 py-4">3</td>
              <td className="px-6 py-4">€21,60</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-50 border-b">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                06/09/2023
              </th>
              <td className="px-6 py-4">Kattenvoer zak 200g vlees & groente</td>
              <td className="px-6 py-4">Accesoires</td>
              <td className="px-6 py-4">11</td>
              <td className="px-6 py-4">€66</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* die andere lijn */}
      <div className="inline-flex items-center justify-center w-full">
        <hr className="w-full h-px my-8 bg-gray-200 border-0" />
        <span className="absolute px-3 text-2xl font-medium text-gray-500 -translate-x-1/2 -translate-y-1 bg-background left-1/2">
          Mijn gegevens
        </span>
      </div>

      {userData ? (
          
        <div className="mx-auto bg-white shadow-lg rounded-lg w-full">
            <div className="p-3 overflow-auto">
                <div className="flex flex-col md:grid md:grid-cols-2 gap-4 mt-2">
                    <div className="h-8 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                      <span className='mr-2 text-gray-500'>Voornaam:</span> {userData.firstname}
                    </div>
                    <div className="h-8 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                      <span className='mr-2 text-gray-500'>Achternaam:</span> {userData.lastname}
                    </div>
                    <div className="h-8 col-span-2 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                      <span className='mr-2 text-gray-500'>Email:</span> {userData.email}
                    </div>
                    <div className="h-8 col-span-2 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                      <span className='mr-2 text-gray-500'>Telefoon:</span> {userData.phone}
                    </div>
                </div>
            </div>
        </div>

      ) : (
        <p>Loading...</p>
      )}

      <div className='mt-6 flex justify-between'>
        <Link
          to="/profile/edit"
          className="block bg-primary rounded-full px-4 py-2 text-white hover:bg-[#16B471]"
        >
          Bewerken
        </Link>

        <button
          onClick={deleteUser}
          className='block bg-red-600 rounded-full px-4 py-2 text-white hover:bg-red-700'
        >
         ! Account Verwijderen !
        </button>
      </div>

      {/* die andere andere lijn */}
      <div className="inline-flex items-center justify-center">
        <hr className="w-full h-px my-8 bg-gray-200 border-0" />
        <span className="absolute px-3 text-2xl font-medium text-gray-500 -translate-x-1/2 -translate-y-1 bg-background left-1/2">
          Mijn kaarten
        </span>
      </div>


      {userData ? (
        <>
          {userCards && userCards.map((card) => (
            <div className="mx-auto bg-white shadow-lg rounded-lg w-full" key={card.id}>
              <div className="p-3 overflow-auto">
                <div className="flex flex-col md:grid md:grid-cols-2 gap-4 mt-2">
                  <div className="min-h-8 col-span-2 bg-gray-200 rounded px-3 flex flex-wrap justify-between items-center overflow-hidden">
                    <div>
                      <span className="mr-2 text-gray-500">Kaart nummer:</span>
                      {card.cardnumber}
                    </div>
                    <div>
                    {/* <a href="/" className="font-medium text-blue-600 hover:underline" >
                      Bewerken
                    </a> */}
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <p>Loading...</p>
      )}

      <div className='mt-6 flex'>
        <Link
          to="/profile/card/add"
          className="block bg-primary rounded-full px-4 py-2 text-white hover:bg-[#16B471]">
          Voeg kaart toe
        </Link>
      </div>

      
    </div>
  );
      }
}
export default Profile;