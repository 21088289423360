import { useParams, Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { axiosAuth } from '../../../utils/axiosInterceptor';
import { Brand } from '../../../utils/interfaces';
import AutoComplete from '../../../components/AutoComplete';

export default function EditProduct() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [brands, setBrands] = useState<Brand[]>([]);

  // Get all brands
  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + '/brands')
      .then((response) => {
        setBrands(response.data.data);
      });
  }, []);

  const [form, setForm] = useState<{
    name: string;
    brand?: Brand;
  }>({
    name: '',
  });

  // Get product data and update form
  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + '/products/find/' + id)
      .then((response) => {
        const productData = response.data.data[0];
        setForm({
          name: productData.name,
          brand: productData.brand,
        });
      });
  }, [id, brands]);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setForm({
      ...form,
      name: e.target.value,
    });
  }

  // Submit form
  function onSubmit() {
    axiosAuth
      .put(process.env.REACT_APP_BACKEND_URL + '/products/update/' + id, {
        name: form.name,
        brand_id: form.brand?.id,
      })
      .catch((error) => {
        console.log(error);
      });

    navigate('/admin/products');
  }

  return (
    <div className="flex flex-col gap-8">
      <h1>Product Bewerken</h1>

      <div className="flex flex-col w-96">
        <label htmlFor="name">Naam</label>
        <input
          type="text"
          name="name"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="name"
          value={form.name}
          onChange={onChange}
          placeholder="Naam"
        />

        <AutoComplete
          placeholder={'Selecteer een merk'}
          data={brands}
          onChange={(item: any) => {
            setForm({
              ...form,
              brand: item,
            });
          }}
          value={form.brand}
        />
      </div>
      <div className="flex flex-row">
        <Link
          to="/admin/products"
          className="bg-primary rounded-full px-4 py-2 text-sm text-white hover:bg-[#16B471] mr-3 flex items-center justify-center w-24"
        >
          <img
            className="h-[20px] w-[20px] min-w-[20px] mr-2"
            src="/back-square.svg"
            alt="go back"
          />
          Terug
        </Link>
        <button
          onClick={onSubmit}
          className="px-4 py-2 text-sm text-white bg-primary rounded-full hover:bg-[#16B471]"
        >
          Opslaan
        </button>
      </div>
    </div>
  );
}
