import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';


export default function EditProfile() {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
      const fetchData = async () => {
      const user_id = Cookies.get('pawloyalty_id');
      const user_token = Cookies.get('pawloyalty_token');

      if (user_id && user_token) {
        try {
          const response = await axios.get(`http://127.0.0.1:8000/api/administrators/${user_id}`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${user_token}`,
            },
          });

          if (response.status === 200) {
            console.log(response.data.administrator);

            setForm({
              firstname: response.data.administrator.firstname,
              lastname: response.data.administrator.lastname,
              email: response.data.administrator.email,
              phone: response.data.administrator.phone,
            });
          } else {

            alert(response.statusText);
          }
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, []); // Empty dependency array to ensure this effect runs only once

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  

  const onSubmit = async () => {
    const user_id = Cookies.get('pawloyalty_id');
    const user_token = Cookies.get('pawloyalty_token');
  
    if (user_id && user_token) {
      try {
        const response = await axios.put(
          `http://127.0.0.1:8000/api/administrators/update/${user_id}`,
          {
            firstname: form.firstname,
            lastname: form.lastname,
            email: form.email,
            phone: form.phone,
          },
          {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${user_token}`,
            },
          }
        );
  
        if (response.status === 201) {
          Cookies.set('pawloyalty_user_firstname', response.data.administrator.firstname, { expires: 7 });
          Cookies.set('pawloyalty_user_lastname', response.data.administrator.lastname, { expires: 7 });
          Cookies.set('pawloyalty_user_email', response.data.administrator.email, { expires: 7 });

          navigate('/admin/profile');
        }
      } catch (error) {
        console.error('There was a problem updating the profile:', error);
      }
    }
    navigate('/admin/profile');
  };

  return (
    <div className="flex flex-col gap-8">
      <h1>Profiel Bewerken</h1>

      <div className="flex flex-col">
        <label htmlFor="firstname">Voornaam</label>
        <input
          type="text"
          name="firstname"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="firstname"
          value={form.firstname}
          onChange={onChange}
        />

        <label htmlFor="lastname">Achternaam</label>
        <input
          type="text"
          name="lastname"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="lastname"
          value={form.lastname}
          onChange={onChange}
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="email"
          value={form.email}
          onChange={onChange}
        />

        <label htmlFor="phone">Telefoon</label>
        <input
          type="text"
          name="phone"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="phone"
          value={form.phone}
          onChange={onChange}
        />
      </div>
      <div className='flex flex-row'>
      <button
            onClick={() => navigate(-1)}
            className="bg-primary rounded-full px-4 py-2 text-sm text-white hover:bg-[#16B471] mr-3 flex items-center justify-center w-24"
        >
            <img className="h-[20px] w-[20px] min-w-[20px] mr-2" src="/back-square.svg" alt="go back" />
            Terug
        </button>
        <button
          onClick={onSubmit}
          className="px-4 py-2 text-sm text-white bg-primary rounded-full hover:bg-[#16B471]"
        >
          Opslaan
        </button>
      </div>
    </div>
  );
}
