import React, { useState } from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import {Md5} from 'ts-md5';


const AdminLoginScreen = () => {

    const [user, setUser] = useState('');
    const [wachtwoord, setWachtwoord] = useState('');
  
    const [errors, setErrors] = useState({
      user: '',
      wachtwoord: '',
      backend_user: '',
      backend_wachtwoord: '',
      backend_message: '',
    });
  
    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setUser(e.target.value);
      setErrors({ ...errors, user: '' });
    };
  
    const handleWachtwoordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setWachtwoord(e.target.value);
      setErrors({ ...errors, wachtwoord: '' });
    };

    const handleAdminLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        // Perform validation logic here
        let isValid = true;
    
        if (user.trim() === '') {
          setErrors({ ...errors, user: 'Vul uw emailadres in' });
          isValid = false;
        }
        if (wachtwoord.trim() === '') {
          setErrors({ ...errors, wachtwoord: 'Vul uw wachtwoord in' });
          isValid = false;
        }
    
        // Add similar validation for other fields
    
        if (isValid) {
            const md5 = new Md5();

            const hashedPassword = md5.appendStr(wachtwoord).end();
          axios.post('http://127.0.0.1:8000/api/administrator/login', 
          {
            email: user,
            password: hashedPassword
          }, {
          headers: {
              // 'Content-Type': 'application/json',
              'Accept': 'application/json',
          },
          })

          .then(response => {
              return response;
          })

          .then(responseData => {
            // check 404
            if (responseData.status === 404) {
                // alert(responseData.statusText);
            } else {
              if (responseData.data.token !== 'undefined') {
                // console.log(responseData);

                Cookies.set('pawloyalty_token', responseData.data.token, { expires: 7 });
                Cookies.set('pawloyalty_role', responseData.data.role, { expires: 7 });
                Cookies.set('pawloyalty_id', responseData.data.administrator.id, { expires: 7 });

                if (Cookies.get('pawloyalty_AcceptCookies') === 'true') {
                  Cookies.set('pawloyalty_user_firstname', responseData.data.administrator.firstname, { expires: 7 });
                  Cookies.set('pawloyalty_user_lastname', responseData.data.administrator.lastname, { expires: 7 });
                  Cookies.set('pawloyalty_user_email', responseData.data.administrator.email, { expires: 7 });
                }

                window.location.href = '/admin';
              }
            }
         })

          .catch(error => {
              console.error('There was a problem with the fetch operation:', error);

              if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.email) {
                setErrors({ ...errors, backend_user: error.response.data.errors.email[0] });
              }

              if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.password) {
                setErrors({ ...errors, backend_wachtwoord: error.response.data.errors.password[0] });
              }

              if (error.response && error.response.data && error.response.data.message) {
                setErrors({ ...errors, backend_message: error.response.data.message });
              }
          });
        }
      };


  return (
    <div className="bg-gradient-to-tr from-[#057D4B] via-[#09AA66] to-[#31C787] min-h-screen flex justify-center">
        <div className="min-h-screen flex justify-center bg-no-repeat bg-left-bottom min-w-[100vw]" style={{ backgroundImage: 'url(/welkom-linksonder.svg)' }}>
        <div className="min-h-screen flex justify-center bg-no-repeat bg-right-top min-w-[100vw]" style={{ backgroundImage: 'url(/welkom-rechtsboven.svg)' }}>
            <div className="flex items-center justify-center flex-col px-10 max-w-[700px] w-full">
                <div className="text-white w-full">
                    <img src="/logo.png" className="max-w-[160px]"></img>

                    <form onSubmit={handleAdminLogin}>
                        <div className="my-8">
                            <div className='flex flex-col gap-5'>
                                <h1 className="text-4xl font-semibold">Admin Login</h1>
                            
                                <div className='flex flex-col gap-1'>
                                    <p className="max-w-[410px] text-xl">Email adres</p>
                                    <input id='emailkaart-input' type='text' onChange={handleUserChange} className={`rounded-full bg-transparent border text-white placeholder:text-white border-white p-2 focus:outline-none w-full xs:w-[60%] ${errors.user ? 'border-red-500' : ''}`} placeholder='typ hier...' />
                                    {errors.user && <p className="text-[#D7403A] text-base">{errors.user}</p>}
                                    {errors.backend_user && <p className="text-[#D7403A] text-base">{errors.backend_user}</p>}
                                </div>

                                <div className='flex flex-col gap-1'>
                                    <p className="max-w-[410px] text-xl">Wachtwoord</p>
                                    <input id='password-input' type='password' onChange={handleWachtwoordChange} className={`rounded-full bg-transparent border text-white placeholder:text-white border-white p-2 focus:outline-none w-full xs:w-[60%] ${errors.wachtwoord ? 'border-red-500' : ''}`} placeholder='typ hier...' />
                                    {errors.wachtwoord && <p className="text-[#D7403A] text-base">{errors.wachtwoord}</p>}
                                    {errors.backend_wachtwoord && <p className="text-[#D7403A] text-base">{errors.backend_user}</p>}
                                </div>
                                <p>{errors.backend_message && <p className="text-[#D7403A] text-base">{errors.backend_message}</p>}</p>

                            </div>
                        </div>

                        <div>
                            <button className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-primary focus:outline-none bg-white rounded-3xl border border-gray-200 hover:bg-gray-100 hover:gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200 w-full">Log in op uw account</button>

                            <Link to="/">
                                <button className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-white focus:outline-none rounded-3xl border border-white hover:gray-900 hover:bg-[#16B471] focus:z-10 focus:ring-4 focus:ring-gray-200 w-full">Ga terug</button>
                            </Link>
                        </div>

                    </form>

                </div>
            </div>
        </div>
        </div>
    </div>
  );
};

export default AdminLoginScreen;