import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderMobile from '../../components/Header-mobile';
import MobileNavbar from '../../components/Mobile-Navigation';
import { useMediaQuery } from '@react-hook/media-query';

  const PrivacyPolicy = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <div className="w-full">
        {isMobile ? <HeaderMobile /> : <Header />}
      </div>
      <div className="py-16 px-32 flex-1 w-full bg-background">
        <div id="content" className="flex justify-center p-6">
          <div id="cookie-beleid" className="max-w-[820px]">
            <h1 className="text-2xl font-bold mb-4">Privacyverklaring</h1>
            <p className="mb-4">
              Deze privacyverklaring is voor het laatst bijgewerkt op januari
              18, 2024 en is van toepassing op burgers en wettelijk permanente
              inwoners van de Europese Economische Ruimte en Zwitserland.
            </p>

            <p className="mb-4">
              In deze privacyverklaring leggen we uit wat we doen met de
              gegevens die we via{' '}
              <a
                href="https://PawLoyalty.nl"
                className="text-blue-500 underline"
              >
                https://PawLoyalty.nl
              </a>{' '}
              over u verkrijgen. We raden u aan deze verklaring aandachtig te
              lezen. In onze verwerking voldoen we aan de vereisten van
              privacywetgeving. Dat betekent onder andere dat:
            </p>

            <ul className="list-disc pl-6 mb-4">
              <li>
                Wij duidelijk vermelden met welke doeleinden wij
                persoonsgegevens verwerken. Dat doen wij via deze
                privacyverklaring;
              </li>
              <li>
                Wij streven ernaar onze verzameling van persoonsgegevens te
                beperken tot enkel de persoonsgegevens die noodzakelijk zijn
                voor legitieme doeleinden;
              </li>
              <li>
                Wij u eerst vragen om uitdrukkelijke toestemming om uw
                persoonsgegevens te verwerken in gevallen waarin uw toestemming
                is vereist;
              </li>
              <li>
                Wij passende beveiligingsmaatregelen nemen om uw
                persoonsgegevens te beschermen en dat ook eisen van partijen die
                in onze opdracht persoonsgegevens verwerken;
              </li>
              <li>
                Wij uw recht respecteren om uw persoonsgegevens op uw aanvraag
                ter inzage te bieden, te corrigeren of te verwijderen.
              </li>
            </ul>

            <p className="mb-4">
              Als u vragen heeft of precies wilt weten welke gegevens wij van u
              bewaren, neem dan contact met ons op.
            </p>

            <h2 className="text-lg font-bold mb-2">
              1. Doel, gegevens en bewaartermijn
            </h2>
            <p className="mb-4">
              Wij ontvangen of verzamelen mogelijk persoonsgegevens voor een
              aantal doeleinden die verband houden met onze bedrijfsvoering,
              waaronder de volgende:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                1.1 Contact – door middel van telefoon, post, e-mail en/of
                webformulieren
              </li>
            </ul>

            <h2 className="text-lg font-bold mb-2">2. Cookies</h2>
            <p className="mb-4">
              Onze site maakt gebruik van cookies. Voor meer informatie over
              cookies verwijzen wij u naar ons Cookiebeleid.
            </p>

            <h2 className="text-lg font-bold mb-2">
              3. Delen met andere partijen
            </h2>
            <p className="mb-4">
              Wij delen persoonsgegevens indien wij bij wet of door een
              gerechtelijk bevel, in antwoord op een wetshandhavingsinstantie,
              voor zover toegestaan onder andere wettelijke bepalingen,
              verplicht zijn om informatie te verstrekken, of voor een onderzoek
              naar een kwestie in verband met de openbare veiligheid.
            </p>
            <p className="mb-4">
              Als onze site of organisatie wordt overgenomen, verkocht, of
              betrokken is bij een fusie of overname, kunnen uw gegevens worden
              doorgegeven aan onze adviseurs en mogelijke kopers en worden
              doorgegeven aan de nieuwe eigenaren.
            </p>

            <h2 className="text-lg font-bold mb-2">4. Beveiliging</h2>
            <p className="mb-4">
              Beveiliging van persoonsgegevens is voor ons van groot belang. Wij
              nemen passende beveiligingsmaatregelen om misbruik van en
              ongeautoriseerde toegang tot persoonsgegevens te beperken. Zo
              zorgen wij dat alleen de noodzakelijke personen toegang hebben tot
              uw gegevens, dat de toegang tot de gegevens afgeschermd is en dat
              onze veiligheidsmaatregelen regelmatig gecontroleerd worden.
            </p>

            <h2 className="text-lg font-bold mb-2">5. Websites van derden</h2>
            <p className="mb-4">
              Deze Privacyverklaring heeft geen betrekking op sites van derden
              waar naar wordt verwezen via links op deze sites. We garanderen
              niet dat deze sites uw persoonsgegevens op een betrouwbare en
              veilige manier behandelen. We raden u aan om de
              privacyverklaringen van deze sites te lezen voor u gebruik maakt
              van deze sites.
            </p>

            <h2 className="text-lg font-bold mb-2">
              6. Wijzigingen in deze privacyverklaring
            </h2>
            <p className="mb-4">
              Wij behouden ons het recht voor om wijzigingen aan te brengen in
              deze privacyverklaring. Het verdient aanbeveling om deze
              privacyverklaring geregeld te raadplegen, zodat u van deze
              wijzigingen op de hoogte bent. Bovendien zullen wij u waar
              mogelijk ook actief op de hoogte brengen.
            </p>

            <h2 className="text-lg font-bold mb-2">
              7. Toegang verkrijgen tot en wijziging van uw gegevens
            </h2>
            <p className="mb-4">
              Als u vragen heeft of wilt weten welke persoonlijke gegevens we
              over u hebben, neem dan contact met ons op. U heeft de volgende
              rechten:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                U hebt het recht om te weten waarom uw persoonsgegevens nodig
                zijn, wat ermee gebeurt en hoe lang deze worden bewaard.
              </li>
              <li>
                Recht op inzage: u kunt een verzoek indienen om inzage in de
                gegevens die we van u verwerken
              </li>
              <li>
                Recht op rectificatie en aanvulling: u hebt het recht om uw
                persoonlijke gegevens aan te vullen, te corrigeren, te
                verwijderen of te blokkeren wanneer u maar wilt.
              </li>
              <li>
                Als u ons toestemming geeft om uw gegevens te verwerken, heeft u
                het recht om die toestemming in te trekken en uw persoonlijke
                gegevens te laten verwijderen.
              </li>
              <li>
                Recht op dataportabiliteit: u hebt het recht om al uw
                persoonlijke gegevens op te vragen bij de
                Verwerkingsverantwoordelijke en deze in zijn geheel over te
                dragen aan een andere Verwerkingsverantwoordelijke.
              </li>
              <li>
                Recht op bezwaar: u kunt bezwaar maken tegen de verwerking van
                uw gegevens. Wij komen hieraan tegemoet, tenzij er gegronde
                redenen voor verwerking zijn.
              </li>
            </ul>
            <p className="mb-4">
              Zorg ervoor dat u altijd duidelijk vermeldt wie u bent, zodat we
              er zeker van kunnen zijn dat we geen gegevens van de verkeerde
              persoon wijzigen of verwijderen.
            </p>

            <h2 className="text-lg font-bold mb-2">8. Een klacht indienen</h2>
            <p className="mb-4">
              Als u niet tevreden bent met de manier waarop wij omgaan met (een
              klacht over) de verwerking van uw persoonsgegevens, heeft u het
              recht om een klacht in te dienen bij de Autoriteit
              Persoonsgegevens.
            </p>

            <h2 className="text-lg font-bold mb-2">9. Contactinformatie</h2>
            <p className="mb-2">PawHaven</p>
            <p className="mb-2">Adres ergerns</p>
            <p className="mb-2">Nederland</p>
            <p className="mb-2">
              Website:{' '}
              <a
                href="https://PawLoyalty.nl"
                className="text-blue-500 underline"
              >
                https://PawLoyalty.nl
              </a>
            </p>
            <p className="mb-2">
              E-mail:{' '}
              <a
                href="mailto:contact@PawLoyalty.nl"
                className="text-blue-500 underline"
              >
                contact@PawLoyalty.nl
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full">
        {isMobile ? <MobileNavbar /> : <Footer />}
        {isMobile && <div className='h-[75px]'></div>}
      </div>
    </>
  );
};

export default PrivacyPolicy;
