import Cookies from 'js-cookie';
import Aanbiedingen from '../../components/client/aanbiedingen';
import OnzeWinkels from '../../components/client/OnzeWinkels';


const getCurrentTimeOfDay = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 4 && currentHour < 12) {
        return 'morgen';
    } else if (currentHour >= 12 && currentHour < 18) {
        return 'middag';
    } else {
        return 'avond';
    }
};

const getCurrentDayAndDate = () => {
    const days = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
    const today = new Date();
    const day = days[today.getDay()];
    const date = today.getDate();
    const month = months[today.getMonth()];
    return `Het is vandaag ${day} ${date} ${month}.`;
};

const ClientHomeScreen = () => {
  return (
    <div className='flex flex-col gap-16'>

        <div>
            <h1 className="text-4xl font-semibold">Goede{getCurrentTimeOfDay()} {Cookies.get('pawloyalty_user_firstname')}</h1>
            <p>{getCurrentDayAndDate()}</p>
        </div>

        <Aanbiedingen />

        <OnzeWinkels />

    </div>
  );
};

export default ClientHomeScreen;