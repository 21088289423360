import AdminLoginScreen from "./pages/admin/Login";
import LoginScreen from "./pages/client/Login";
import RegisterScreen from "./pages/client/Register";
import WelcomeScreen from "./pages/welcome";
import ClientHome from "./pages/client/home";
import AdminHome from "./pages/admin/home";
import NavigationMore from "./pages/navigation-more";


import AddPet from "./pages/client/pet/AddPet";
import ContactScreen from "./pages/client/contact";
import Pagenotfound from "./pages/Pagenotfound";
import Cookiebeleid from "./pages/avg/cookie-beleid";
import PrivacyPolicy from "./pages/avg/PrivacyPolicy";
import AlgemeneVoorwaarden from "./pages/avg/AlgemeneVoorwaarden";
import Products from "./pages/admin/products/Products";
import Pets from "./pages/client/pet/Pets";
import Brands from "./pages/admin/brands/Brands";
import Profile from "./pages/client/profile/Profile";
import EditProfile from "./pages/client/profile/EditProfile";
import AdminProfile from "./pages/admin/profile/Profile";
import AdminEditProfile from "./pages/admin/profile/EditProfile";
import ListClient from "./pages/admin/client/List";
import ListDeactivatedClient from "./pages/admin/client/List-deactivated";
import ProfileClient from "./pages/admin/client/profile";
import SearchClient from "./pages/admin/client/Search";
import EditPet from "./pages/client/pet/EditPet";
import CardAdd from "./pages/client/profile/CardAdd";
import EditBrand from "./pages/admin/brands/EditBrand";
import AddBrand from "./pages/admin/brands/AddBrand";
import Species from "./pages/admin/species/Species";
import AddSpecies from "./pages/admin/species/AddSpecies";
import EditSpecies from "./pages/admin/species/EditSpecies";
import Breeds from "./pages/admin/breeds/Breeds";
import EditBreed from "./pages/admin/breeds/EditBreed";
import AddBreed from "./pages/admin/breeds/AddBreed";
import EditProduct from "./pages/admin/products/EditProduct";
import AddProduct from "./pages/admin/products/AddProduct";

type Route = {
  path: string;
  component: any;
};

export const adminRoutes: Route[] = [
  {
    path: '/',
    component: <AdminHome />,
  },
  {
    path: '/species',
    component: <Species />,
  },
  {
    path: '/species/edit/:id',
    component: <EditSpecies />,
  },
  {
    path: '/species/add',
    component: <AddSpecies />,
  },
  {
    path: '/breeds',
    component: <Breeds />,
  },
  {
    path: '/breeds/edit/:id',
    component: <EditBreed />,
  },
  {
    path: '/breeds/add',
    component: <AddBreed />,
  },
  {
    path: '/products',
    component: <Products />,
  },
  {
    path: '/brands',
    component: <Brands />,
  },
  {
    path: '/brands/edit/:id',
    component: <EditBrand />,
  },
  {
    path: '/brands/add',
    component: <AddBrand />,
  },
  {
    path: '/products',
    component: <Products />,
  },
  {
    path: '/products/edit/:id',
    component: <EditProduct />,
  },
  {
    path: '/products/add',
    component: <AddProduct />,
  },
  {
    path: '/clients',
    component: <ListClient />,
  },
  {
    path: '/clients/deactivated',
    component: <ListDeactivatedClient />,
  },
  {
    path: '/clients/profile/:id',
    component: <ProfileClient />,
  },
  {
    path: '/client/search',
    component: <SearchClient />,
  },
  {
    path: '/profile',
    component: <AdminProfile />,
  },
  {
    path: '/profile/edit',
    component: <AdminEditProfile />,
  },
];

export const clientRoutes: Route[] = [
  {
    path: '/client',
    component: <ClientHome />,
  },
  {
    path: '/pets/add',
    component: <AddPet />,
  },
  {
    path: '/profile',
    component: <Profile />,
  },
  {
    path: '/profile/card/add',
    component: <CardAdd />,
  },
  {
    path: '/profile/edit',
    component: <EditProfile />,
  },
  {
    path: '/pets',
    component: <Pets />,
  },
  {
    path: '/pets/edit/:id',
    component: <EditPet />,
  }
];

export const VisitorRoutes: Route[] = [
  {
    path: '/',
    component: <WelcomeScreen/>,
  },
  {
    path: '/login',
    component: <LoginScreen/>,
  },
  {
    path: '/register',
    component: <RegisterScreen/>,
  },
];

export const publicRoutes: Route[] = [
  {
    path: '/',
    component: <WelcomeScreen/>,
  },
  {
    path: '/admin/login',
    component: <AdminLoginScreen/>,
  },
  {
    path: '/contact',
    component: <ContactScreen/>,
  },
  {
    path: '/cookie-beleid',
    component: <Cookiebeleid />,
  },
  {
    path: '/PrivacyPolicy',
    component: <PrivacyPolicy />,
  },
  {
    path: '/AlgemeneVoorwaarden',
    component: <AlgemeneVoorwaarden />,
  },
  {
    path: '/navigation-more',
    component: <NavigationMore />,
  },
  {
    path: '*',
    component: <Pagenotfound />,
  },
];