import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeaderMobile from '../../components/Header-mobile';
import MobileNavbar from '../../components/Mobile-Navigation';
import { useMediaQuery } from '@react-hook/media-query';

const Cookiebeleid = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <div className="w-full">
        {isMobile ? <HeaderMobile /> : <Header />}
      </div>
      <div className="py-16 px-32 flex-1 w-full bg-background">
        <div id="content" className="flex justify-center p-6">
          <div id="cookie-beleid" className="max-w-[820px]">
            <h1 className="mt-5 font-bold">Cookiebeleid</h1>
            <p className="mb-4">
              Dit Cookiebeleid is voor het laatst bijgewerkt op januari 24, 2024
              en is van toepassing op burgers en wettelijk permanente inwoners
              van de Europese Economische Ruimte en Zwitserland.
            </p>
            <h2 className="text-lg font-bold mb-2">1. Introductie</h2>
            <p className="mb-4">
              Op onze site, https://PawLoyalty.nl (hierna: “de site”) wordt
              gebruikgemaakt van cookies en andere aanverwante technieken.
              (Hierna geldt dat alle technieken voor het gemak “cookies” worden
              genoemd). Ook via derden die door ons zijn ingeschakeld, worden
              cookies geplaatst. In het onderstaande document informeren wij u
              over het gebruik van cookies op onze site.
            </p>
            <h2 className="text-lg font-bold mb-2">2. Wat zijn cookies</h2>
            <p className="mb-4">
              Een cookie is een eenvoudig klein bestandje dat met pagina’s van
              deze site wordt meegestuurd en door uw browser op uw harde schrijf
              van uw computer of ander apparaat wordt opgeslagen. De daarin
              opgeslagen informatie kan bij een volgend bezoek weer naar onze
              servers of die van de betreffende derde partijen teruggestuurd
              worden.
            </p>
            <h2 className="text-lg font-bold mb-2">3. Wat zijn scripts?</h2>
            <p className="mb-4">
              Een script is een stukje programmacode dat wordt gebruikt om onze
              site goed te laten functioneren en interactief te maken. Deze code
              wordt uitgevoerd op onze server, of op uw apparatuur.
            </p>
            <h2 className="text-lg font-bold mb-2">
              4. Wat is een web beacon?
            </h2>
            <p className="mb-4">
              Een web beacon (ook pixeltag) is een klein, onzichtbaar stukje
              tekst of afbeelding op een site dat gebruikt wordt om verkeer op
              een site in kaart te brengen. Om dit te doen worden er met behulp
              van web beacons verschillende gegevens van u opgeslagen.
            </p>
            <h2 className="text-lg font-bold mb-2">5. Cookies</h2>
            <h3 className="text-md font-semibold mb-1">
              5.1 Technische of functionele cookies
            </h3>
            <p className="mb-4">
              Sommige cookies zorgen ervoor dat bepaalde onderdelen van de site
              goed werken en dat uw gebruikersvoorkeuren bekend blijven. Door
              het plaatsen van functionele cookies zorgen wij ervoor dat u onze
              site makkelijker kunt bezoeken. Op deze manier hoeft u
              bijvoorbeeld niet steeds opnieuw dezelfde informatie in te voeren
              bij een bezoek aan onze site en is het onder andere mogelijk dat
              de artikelen in uw winkelmandje bewaard blijven tot dat u heeft
              afgerekend. Deze cookies mogen wij plaatsen zonder dat u hier
              toestemming voor geeft.
            </p>
            <h3 className="text-md font-semibold mb-1">
              5.2 Statistieken cookies
            </h3>
            <p className="mb-4">
              Wij gebruiken statistieken cookies om de beleving voor onze
              gebruikers te optimaliseren. Wij krijgen door middel van
              statistieken cookies inzicht in het gebruik van onze site. Wij
              vragen uw toestemming om statistieken cookies te plaatsen.
            </p>
            <h3 className="text-md font-semibold mb-1">
              5.3 Marketing/Tracking cookies
            </h3>
            <p className="mb-4">
              Marketing/Tracking cookies zijn cookies of enige andere vorm van
              local storage, die worden gebruikt om gebruikersprofielen te maken
              met het doel om advertenties te tonen of om de gebruiker te volgen
              op deze of verschillende sites voor soortgelijke
              marketingdoeleinden.
            </p>
            <h3 className="text-md font-semibold mb-1">5.4 Sociale media</h3>
            <p className="mb-4">
              Op onze site hebben wij inhoud opgenomen om webpagina’s te
              promoten (bijv. “vind ik leuk”, “pin”) of te delen (bijv. “tweet”)
              op sociale netwerken. Deze inhoud is ingesloten met code die
              afkomstig is van derden en plaatst cookies. Deze inhoud kan
              bepaalde informatie opslaan en verwerken voor gepersonaliseerde
              reclame.
            </p>
            <p className="mb-4">
              Leest u de privacyverklaring van deze partijen door (welke
              regelmatig kunnen wijzigen) om te weten wat zij met uw
              (persoons)gegevens doen die zij via deze cookies verwerken. De
              informatie die zij verzamelen, wordt zo veel mogelijk
              geanonimiseerd.
            </p>
            <h2 className="text-lg font-bold mb-2">6. Geplaatste cookies</h2>
              <ul className='list-disc'>
                <li>Token ( authenticatie )</li>
                <li>Rol ( authenticatie )</li>
                <li>Voornaam</li>
                <li>Achternaam</li>
                <li>Email</li>
              </ul>
            <h2 className="text-lg font-bold mb-2">7. Toestemming</h2>
            <p className="mb-4">
              Wanneer u onze site voor het eerst bezoekt, tonen wij een pop-up
              met uitleg over cookies. Zodra u klikt op ‘Voorkeuren bewaren’
              geeft u ons toestemming om de categorieën cookies en plug-ins te
              gebruiken die u heeft geselecteerd in de pop-up en welke zijn
              omschreven in het Cookiebeleid. U kunt via uw browser het plaatsen
              van cookies uitschakelen, u moet er dan wel rekening mee houden
              dat onze site dan mogelijk niet meer optimaal werkt.
            </p>
            <h2 className="text-lg font-bold mb-2">
              8. Cookies in- / uitschakelen en verwijderen
            </h2>
            <p className="mb-4">
              Via uw internetbrowser kunt u cookies automatisch of handmatig
              verwijderen. U kunt ook aangeven dat bepaalde cookies niet
              geplaatst mogen worden. Een andere mogelijkheid is dat u uw
              internetbrowser zodanig instelt dat u een bericht ontvangt als er
              een cookie wordt geplaatst. Meer informatie over deze
              mogelijkheden kunt u vinden in de instructies van de Help-functie
              van uw browser.
            </p>
            <p className="mb-4">
              Let op: onze site werkt mogelijk niet optimaal als alle cookies
              zijn uitgeschakeld. Als u wel de cookies in uw browser verwijdert,
              worden ze na uw toestemming opnieuw geplaatst bij een nieuw bezoek
              aan onze site.
            </p>
            <h2 className="text-lg font-bold mb-2">
              9. Uw rechten met betrekking tot persoonsgegevens
            </h2>
            <p>
              U heeft de volgende rechten met betrekking tot uw
              persoonsgegevens:
            </p>
            <ul className="list-disc">
              <li>
                U hebt het recht om te weten waarom uw persoonsgegevens nodig
                zijn, wat ermee gebeurt en hoe lang deze worden bewaard.
              </li>
              <li>
                op inzage: u kunt een verzoek indienen om inzage in de gegevens
                die we van u verwerken.
              </li>
              <li>
                Recht op rectificatie en aanvulling: u hebt het recht om uw
                persoonlijke gegevens aan te vullen, te corrigeren, te
                verwijderen of te blokkeren wanneer u maar wilt.
              </li>
              <li>
                Als u ons toestemming geeft om uw gegevens te verwerken, heeft u
                het recht om die toestemming in te trekken en uw persoonlijke
                gegevens te laten verwijderen.
              </li>
              <li>
                Recht op dataportabiliteit: u hebt het recht om al uw
                persoonlijke gegevens op te vragen bij de
                Verwerkingsverantwoordelijke en deze in zijn geheel over te
                dragen aan een andere Verwerkingsverantwoordelijke.
              </li>
              <li>
                Recht op bezwaar: u kunt bezwaar maken tegen de verwerking van
                uw gegevens. Wij komen hieraan tegemoet, tenzij er gegronde
                redenen voor verwerking zijn.
              </li>
              <li>
                Om deze rechten uit te kunnen oefenen, kunt u contact met ons
                opnemen. Zie hiervoor de contactinformatie onder aan dit
                Cookiebeleid. Heeft u een klacht over hoe we met uw gegevens
                omgaan, dan horen wij dit graag van u, maar u heeft ook het
                recht om een klacht in te dienen bij de toezichthouder (de
                Autoriteit Persoonsgegevens).
              </li>
            </ul>
            <h2 className="text-lg font-bold mb-2">9. Contactinformatie</h2>
            <p className="mb-2">PawHaven</p>
            <p className="mb-2">Adres ergerns</p>
            <p className="mb-2">Nederland</p>
            <p className="mb-2">
              Website:{' '}
              <a
                href="https://PawLoyalty.nl"
                className="text-blue-500 underline"
              >
                https://PawLoyalty.nl
              </a>
            </p>
            <p className="mb-2">
              E-mail:{' '}
              <a
                href="mailto:contact@PawLoyalty.nl"
                className="text-blue-500 underline"
              >
                contact@PawLoyalty.nl
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full">
        {isMobile ? <MobileNavbar /> : <Footer />}
        {isMobile && <div className='h-[75px]'></div>}
      </div>
    </>
  );
};

export default Cookiebeleid;
