import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { axiosAuth } from '../../../utils/axiosInterceptor';


export default function EditProfile() {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    axiosAuth.get(process.env.REACT_APP_BACKEND_URL + `/customers/${Cookies.get("pawloyalty_id")}`).then((response) => {
      setForm({
        firstname: response.data.customer.firstname,
        lastname: response.data.customer.lastname,
        email: response.data.customer.email,
        phone: response.data.customer.phone,
      });
    });
  }, []); // Empty dependency array to ensure this effect runs only once

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  
  const onSubmit = async () => {
    axiosAuth.put(process.env.REACT_APP_BACKEND_URL + `/customers/update/${Cookies.get("pawloyalty_id")}`, {
      firstname: form.firstname,
      lastname: form.lastname,
      email: form.email,
      phone: form.phone,
    }).then((response) => {
        Cookies.set('pawloyalty_user_firstname', response.data.customer.firstname, { expires: 7 });
        Cookies.set('pawloyalty_user_lastname', response.data.customer.lastname, { expires: 7 });
        Cookies.set('pawloyalty_user_email', response.data.customer.email, { expires: 7 });
      navigate('/profile');
    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <div className="flex flex-col gap-8">
      <h1>Profiel Bewerken</h1>

      <div className="flex flex-col w-96">
        <label htmlFor="firstname">Voornaam</label>
        <input
          type="text"
          name="firstname"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="firstname"
          value={form.firstname}
          onChange={onChange}
          placeholder='Voornaam'
        />

        <label htmlFor="lastname">Achternaam</label>
        <input
          type="text"
          name="lastname"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="lastname"
          value={form.lastname}
          onChange={onChange}
          placeholder='Achternaam'
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="email"
          value={form.email}
          onChange={onChange}
          placeholder='Email-adres'
        />

        <label htmlFor="phone">Telefoon</label>
        <input
          type="text"
          name="phone"
          className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          id="phone"
          value={form.phone}
          onChange={onChange}
          placeholder='Telefoonnummer'
        />
      </div>
      <div className='flex flex-row'>
      <button
            onClick={() => navigate(-1)}
            className="bg-primary rounded-full px-4 py-2 text-sm text-white hover:bg-[#16B471] mr-3 flex items-center justify-center w-24"
        >
            <img className="h-[20px] w-[20px] min-w-[20px] mr-2" src="/back-square.svg" alt="go back" />
            Terug
        </button>
        <button
          onClick={onSubmit}
          className="px-6 py-2 text-sm text-white bg-primary rounded-full hover:bg-[#16B471]"
        >
          Opslaan
        </button>
      </div>
    </div>
  );
}
