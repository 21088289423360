import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Md5 } from 'ts-md5';
import { axiosAuth } from '../../utils/axiosInterceptor';

const RegisterScreen = () => {
  const navigate = useNavigate();

  const [voornaam, setVoornaam] = useState('');
  const [achternaam, setAchternaam] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [kaart, setKaart] = useState('2401160930'); // Default value for optional field
  const [wachtwoord, setWachtwoord] = useState('');
  const [wachtwoord_herhalen, setWachtwoord_herhalen] = useState('');

  const [errors, setErrors] = useState({
    voornaam: '',
    achternaam: '',
    email: '',
    tel: '',
    kaart: '',
    wachtwoord: '',
    wachtwoord_herhalen: '',
    backend_password: '',
    backend_phone: '',
    backend_email: '',
    backend_lastname: '',
    backend_firstname: '',
    backend_cardnumber: '',
  });

  const handleVoornaamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVoornaam(e.target.value);
    setErrors({ ...errors, voornaam: '' });
  };

  const handleAchternaamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAchternaam(e.target.value);
    setErrors({ ...errors, achternaam: '' });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: '' });
  };

  const handleTelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTel(e.target.value);
    setErrors({ ...errors, tel: '' });
  };

  const handleKaartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Replace the entire input with '2401160930' if it's not present or if the user tries to remove it
    setKaart(inputValue.startsWith('2401160930') ? inputValue : '2401160930');
    setErrors({ ...errors, kaart: '' });
  };

  const handleWachtwoordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWachtwoord(e.target.value);
    setErrors({ ...errors, wachtwoord: '' });
  };

  const handleWachtwoordHerhalenChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWachtwoord_herhalen(e.target.value);
    setErrors({ ...errors, wachtwoord_herhalen: '' });
  };

  const handleRegister = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Perform validation logic here
    let isValid = true;

    if (voornaam.trim() === '') {
      setErrors({ ...errors, voornaam: 'Vul uw voornaam in' });
      isValid = false;
    }
    if (achternaam.trim() === '') {
      setErrors({ ...errors, achternaam: 'Vul uw achternaam in' });
      isValid = false;
    }
    if (email.trim() === '') {
      setErrors({ ...errors, email: 'Vul uw e-mailadres in' });
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setErrors({ ...errors, email: 'Voer een geldig e-mailadres in' });
      isValid = false;
    }
    if (tel.trim() === '') {
      setErrors({ ...errors, tel: 'Vul uw telefoonnummer in' });
      isValid = false;
    } else if (!/^\d{10,15}$/.test(tel)) {
      setErrors({ ...errors, tel: 'Telefoonnummer is niet geldig' });
      isValid = false;
    }
    if (kaart.trim() === '') {
      setErrors({ ...errors, kaart: 'Vul een kaart nummer in' });
      isValid = false;
    }
    if (wachtwoord.trim() === '') {
      setErrors({ ...errors, wachtwoord: 'Maak een wachtwoord aan' });
      isValid = false;
    } else if (!/(?=.*[A-Z])/.test(wachtwoord)) {
      setErrors({
        ...errors,
        wachtwoord: 'Het wachtwoord moet minstens één hoofdletter bevatten',
      });
      isValid = false;
    } else if (!/(?=.*[a-z])/.test(wachtwoord)) {
      setErrors({
        ...errors,
        wachtwoord: 'Het wachtwoord moet minstens één kleine letter bevatten',
      });
      isValid = false;
    } else if (!/(?=.*\d)/.test(wachtwoord)) {
      setErrors({
        ...errors,
        wachtwoord: 'Het wachtwoord moet minstens één cijfer bevatten',
      });
      isValid = false;
    } else if (!/(?=.*[!@#$%^&*?])/.test(wachtwoord)) {
      setErrors({
        ...errors,
        wachtwoord:
          'Het wachtwoord moet minstens één speciaal karakter bevatten',
      });
      isValid = false;
    } else if (wachtwoord.length < 12) {
      setErrors({
        ...errors,
        wachtwoord: 'Het wachtwoord moet minstens 12 tekens lang zijn',
      });
      isValid = false;
    }

    if (wachtwoord.trim() === '') {
      setErrors({
        ...errors,
        wachtwoord_herhalen: 'Voer je wachtwoord nogmaals in',
      });
      isValid = false;
    }

    // Add similar validation for other fields

    if (isValid) {
      const md5 = new Md5();
      const hashedPassword = md5.appendStr(wachtwoord).end();

      const md5_herhalen = new Md5();
      const hashedPasswordHerhalen = md5_herhalen
        .appendStr(wachtwoord_herhalen)
        .end();

      axiosAuth
        .post(process.env.REACT_APP_BACKEND_URL + '/customers/register', {
          cardnumber: kaart,
          firstname: voornaam,
          lastname: achternaam,
          email: email,
          phone: tel,
          password: wachtwoord,
          password_confirmation: wachtwoord,
        })

        .then((response) => {
          return response;
        })

        .then((responseData) => {
          // check 404
          if ((responseData as any).errors) {
          } else {
            if (responseData.status === 404) {
              alert(responseData.statusText);
            } else {
              Cookies.set('pawloyalty_token', responseData.data.token, {
                expires: 7,
              });
              Cookies.set('pawloyalty_role', responseData.data.role, {
                expires: 7,
              });
              Cookies.set('pawloyalty_id', responseData.data.customer.id, {
                expires: 7,
              });

              if (Cookies.get('pawloyalty_AcceptCookies') === 'true') {
                Cookies.set(
                  'pawloyalty_user_firstname',
                  responseData.data.customer.firstname,
                  { expires: 7 }
                );
                Cookies.set(
                  'pawloyalty_user_lastname',
                  responseData.data.customer.lastname,
                  { expires: 7 }
                );
                Cookies.set(
                  'pawloyalty_user_email',
                  responseData.data.customer.email,
                  { expires: 7 }
                );
              }

              navigate('/pets/add');
            }
          }
        })

        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);

          const resetErrors = () => {
            setErrors({
              voornaam: '', // Add default value for voornaam
              achternaam: '', // Add default value for achternaam
              email: '', // Add default value for email
              tel: '', // Add default value for tel
              kaart: '', // Add default value for kaart
              wachtwoord: '', // Add default value for wachtwoord
              wachtwoord_herhalen: '', // Add default value for wachtwoord_herhalen
              backend_password: '', // Reset backend_password
              backend_phone: '', // Reset backend_phone
              backend_email: '', // Reset backend_email
              backend_lastname: '', // Reset backend_lastname
              backend_firstname: '', // Reset backend_firstname
              backend_cardnumber: '', // Reset backend_cardnumber
            });
          };

          resetErrors();
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.password
          ) {
            setErrors({
              ...errors,
              backend_password: error.response.data.errors.password[0],
            });
          }

          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.email
          ) {
            setErrors({
              ...errors,
              backend_email: error.response.data.errors.email[0],
            });
          }

          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.lastname
          ) {
            setErrors({
              ...errors,
              backend_lastname: error.response.data.errors.lastname[0],
            });
          }

          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.firstname
          ) {
            setErrors({
              ...errors,
              backend_firstname: error.response.data.errors.firstname[0],
            });
          }

          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.cardnumber
          ) {
            setErrors({
              ...errors,
              backend_cardnumber: error.response.data.errors.cardnumber[0],
            });
          }
        });
    }
  };
  if (Cookies.get('token')) {
    navigate('/pets/add');
  }

  return (
    <div className="bg-gradient-to-tr from-[#057D4B] via-[#09AA66] to-[#31C787] min-h-screen flex justify-center py-6">
      <div
        className="min-h-screen flex justify-center bg-no-repeat bg-left-bottom min-w-[100vw]"
        style={{ backgroundImage: 'url(/welkom-linksonder.svg)' }}
      >
        <div
          className="min-h-screen flex justify-center bg-no-repeat bg-right-top min-w-[100vw]"
          style={{ backgroundImage: 'url(/welkom-rechtsboven.svg)' }}
        >
          <div className="flex items-center justify-center flex-col px-10 max-w-[700px] w-full">
            <div className="text-white w-full">
              <img src="/logo.png" alt='logo' className="max-w-[160px]"></img>

              <form onSubmit={handleRegister}>
                <div className="my-8">
                  <div className="flex flex-col gap-5">
                    <h1 className="text-4xl font-semibold">Registreren</h1>

                    <div className="flex flex-col gap-1">
                      <p className="max-w-[410px] text-xl">Voornaam</p>
                      <input
                        id="register-input-voornaam"
                        type="text"
                        onChange={handleVoornaamChange}
                        className={`rounded-full bg-transparent border text-white placeholder:text-white border-white p-2 focus:outline-none w-full xs:w-[60%] ${
                          errors.voornaam ? 'border-red-500' : ''
                        }`}
                        placeholder="typ hier..."
                      />
                      {errors.voornaam && (
                        <p className="text-[#D7403A] text-base">
                          {errors.voornaam}
                        </p>
                      )}
                      {errors.backend_firstname && (
                        <p className="text-[#D7403A] text-base">
                          {errors.backend_firstname}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <p className="max-w-[410px] text-xl">Achternaam</p>
                      <input
                        id="register-input-achternaam"
                        type="text"
                        onChange={handleAchternaamChange}
                        className={`rounded-full bg-transparent border text-white placeholder:text-white border-white p-2 focus:outline-none w-full xs:w-[60%] ${
                          errors.achternaam ? 'border-red-500' : ''
                        }`}
                        placeholder="typ hier..."
                      />
                      {errors.achternaam && (
                        <p className="text-[#D7403A] text-base">
                          {errors.achternaam}
                        </p>
                      )}
                      {errors.backend_lastname && (
                        <p className="text-[#D7403A] text-base">
                          {errors.backend_lastname}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <p className="max-w-[410px] text-xl">Email adres</p>
                      <input
                        id="register-input-email"
                        type="email"
                        onChange={handleEmailChange}
                        className={`rounded-full bg-transparent border text-white placeholder:text-white border-white p-2 focus:outline-none w-full xs:w-[60%] ${
                          errors.email ? 'border-red-500' : ''
                        }`}
                        placeholder="typ hier..."
                      />
                      {errors.email && (
                        <p className="text-[#D7403A] text-base">
                          {errors.email}
                        </p>
                      )}
                      {errors.backend_email && (
                        <p className="text-[#D7403A] text-base">
                          {errors.backend_email}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <p className="max-w-[410px] text-xl">Telefoon nummer</p>
                      <input
                        id="register-input-tel"
                        type="tel"
                        onChange={handleTelChange}
                        className={`rounded-full bg-transparent border text-white placeholder:text-white border-white p-2 focus:outline-none w-full xs:w-[60%] ${
                          errors.tel ? 'border-red-500' : ''
                        }`}
                        placeholder="typ hier..."
                      />
                      {errors.tel && (
                        <p className="text-[#D7403A] text-base">{errors.tel}</p>
                      )}
                      {errors.backend_phone && (
                        <p className="text-[#D7403A] text-base">
                          {errors.backend_phone}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <p className="max-w-[410px] text-xl">
                        Klanten kaart nummer{' '}
                        <span className="text-sm">(optioneel)</span>
                      </p>
                      <input
                        id="register-input-kaart"
                        type="text"
                        onChange={handleKaartChange}
                        className={`rounded-full bg-transparent border text-white placeholder:text-white border-white p-2 focus:outline-none w-full xs:w-[60%] ${
                          errors.kaart ? 'border-red-500' : ''
                        }`}
                        placeholder="typ hier..."
                        value={kaart}
                      />
                      {errors.kaart && (
                        <p className="text-[#D7403A] text-base">
                          {errors.kaart}
                        </p>
                      )}
                      {errors.backend_cardnumber && (
                        <p className="text-[#D7403A] text-base">
                          {errors.backend_cardnumber}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <p className="max-w-[410px] text-xl">Wachtwoord</p>
                      <input
                        id="register-input-wachtwoord"
                        type="password"
                        onChange={handleWachtwoordChange}
                        className={`rounded-full bg-transparent border text-white placeholder:text-white border-white p-2 focus:outline-none w-full xs:w-[60%] ${
                          errors.wachtwoord ? 'border-red-500' : ''
                        }`}
                        placeholder="typ hier..."
                      />
                      {errors.wachtwoord && (
                        <p className="text-[#D7403A] text-base">
                          {errors.wachtwoord}
                        </p>
                      )}
                      {errors.backend_password && (
                        <p className="text-[#D7403A] text-base">
                          {errors.backend_password}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <p className="max-w-[410px] text-xl">
                        Wachtwoord herhalen
                      </p>
                      <input
                        id="register-input-wachtwoord"
                        type="password"
                        onChange={handleWachtwoordHerhalenChange}
                        className={`rounded-full bg-transparent border text-white placeholder:text-white border-white p-2 focus:outline-none w-full xs:w-[60%] ${
                          errors.wachtwoord_herhalen ? 'border-red-500' : ''
                        }`}
                        placeholder="typ hier..."
                      />
                      {errors.wachtwoord_herhalen && (
                        <p className="text-[#D7403A] text-base">
                          {errors.wachtwoord_herhalen}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-primary focus:outline-none bg-white rounded-3xl border border-gray-200 hover:bg-gray-100 hover:gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200 w-full"
                  >
                    Maak mijn account aan
                  </button>

                  <Link to="/"
                   >
                    <button className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-white focus:outline-none rounded-3xl border border-white hover:gray-900 hover:bg-[#16B471] focus:z-10 focus:ring-4 focus:ring-gray-200 w-full flex flex-row justify-center">
                    <img className="h-[20px] w-[20px] min-w-[20px] mr-2" src="/back-square.svg" alt="go back" />
                      Ga terug
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
