const ClientAanbiedingen = () => {
return (
<div>
    <div className="flex flex-col items-center justify-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-90">Onze winter aanbiedingen</h2>
        <p className="text-sm">Gelding t/m 20 maart 2024</p>
    </div>

    <div className="flex flex-wrap items-center justify-center">
        <div className="relative flex-shrink-0 w-[235px] max-w-xs mx-2 mb-6 overflow-hidden bg-yellow-500 rounded-lg shadow-lg">
            <svg className="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none">
                <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)"
                    fill="#f3c06b">
                </rect>
                <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="#f3c06b">
                </rect>
            </svg>
            <div className="relative flex items-center justify-center px-10 pt-10">
                <div className="absolute bottom-0 left-0 block w-48 h-48 ml-3 -mb-24">
                </div>
                <img className="relative w-auto h-[150px]" src="/products/hondenmand.webp" alt="hondenmand" />
            </div>
            <div className="relative px-6 pb-6 mt-6 text-white">
                <span className="block -mb-1 opacity-75">
                    Binnen
                </span>
                <div className="flex justify-between">
                    <span className="block text-xl font-semibold pr-2">
                        Hondenmand
                    </span>
                    <span
                        className="flex items-center px-3 py-2 text-xs font-bold leading-none text-yellow-500 bg-white rounded-full">
                        €50,95
                    </span>
                </div>
            </div>
        </div>
        <div
            className="relative flex-shrink-0 w-[235px] max-w-xs mx-2 mb-6 overflow-hidden bg-blue-500 rounded-lg shadow-lg sm:mb-0">
            <svg className="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none">
                <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)"
                    fill="#6da3fb">
                </rect>
                <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="#6da3fb">
                </rect>
            </svg>
            <div className="relative flex items-center justify-center px-10 pt-10">
                <div className="absolute bottom-0 left-0 block w-48 h-48 ml-3 -mb-24">
                </div>
                <img className="relative w-auto h-[150px]" src="/products/honden-winterjas.webp" alt="honden winterjas" />
            </div>
            <div className="relative px-6 pb-6 mt-6 text-white">
                <span className="block -mb-1 opacity-75">
                    Buiten
                </span>
                <div className="flex justify-between">
                    <span className="block text-xl font-semibold pr-2">
                        Hondenkleding
                    </span>
                    <span
                        className="flex items-center px-3 py-2 text-xs font-bold leading-none text-blue-500 bg-white rounded-full">
                        €69,99
                    </span>
                </div>
            </div>
        </div>
        <div className="relative flex-shrink-0 w-[235px] max-w-xs mx-2 -mb-6 overflow-hidden bg-purple-500 rounded-lg shadow-lg">
            <svg className="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none">
                <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)"
                    fill="#a17cf3">
                </rect>
                <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="#a17cf3">
                </rect>
            </svg>
            <div className="relative flex items-center justify-center px-10 pt-10">
                <div className="absolute bottom-0 left-0 block w-48 h-48 ml-3 -mb-24">
                </div>
                <img className="relative w-auto h-[150px]" src="/products/kattenmand.webp" alt="kattenmand" />
            </div>
            <div className="relative px-6 pb-6 mt-6 text-white">
                <span className="block -mb-1 opacity-75">
                    Binnen
                </span>
                <div className="flex justify-between">
                    <span className="block text-xl font-semibold pr-2">
                        Kattenmand
                    </span>
                    <span
                        className="flex items-center px-3 py-2 text-xs font-bold leading-none text-purple-500 bg-white rounded-full">
                        €37,49
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
)
}

export default ClientAanbiedingen;