import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import axios from 'axios';
import { useMediaQuery } from '@react-hook/media-query';


const CookiesPopup = () => {
  const [showCookiePopup, setShowCookiePopup] = useState(
    typeof Cookies.get('pawloyalty_AcceptCookies') === 'undefined'
  );

  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleRefuse = () => {
    Cookies.set('pawloyalty_AcceptCookies', 'false', { expires: 7 });
    setShowCookiePopup(false);

    Cookies.remove('pawloyalty_user_firstname');
    Cookies.remove('pawloyalty_user_lastname');
    Cookies.remove('pawloyalty_user_email');

    window.location.reload();
  };

  const handleAccept = async () => {
    Cookies.set('pawloyalty_AcceptCookies', 'true', { expires: 7 });
    setShowCookiePopup(false);

    const user_token = Cookies.get('pawloyalty_token');
    const user_id = Cookies.get('pawloyalty_id');
    const user_role = Cookies.get('pawloyalty_role');
    if (user_id) {
      try {
        let response;
        let apiUrl;

        if (user_role === 'ROLE_CUSTOMER') {
          apiUrl = `http://127.0.0.1:8000/api/customers/${user_id}`;
        } else if (user_role === 'ROLE_ADMINISTRATOR') {
          apiUrl = `http://127.0.0.1:8000/api/administrators/${user_id}`;
        } else {
          throw new Error('Invalid user role');
        }

        response = await axios.get(apiUrl,
        {
          headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${user_token}`,
          },
        });

        if (response.status === 404) {
          alert(response.statusText);
        } else {
          console.log(response.data);

          if (user_role === 'ROLE_CUSTOMER') {
            Cookies.set('pawloyalty_user_firstname', response.data.customer.firstname, { expires: 7 });
            Cookies.set('pawloyalty_user_lastname', response.data.customer.lastname, { expires: 7 });
            Cookies.set('pawloyalty_user_email', response.data.customer.email, { expires: 7 });
          } else {
            console.log('else');
            if (user_role === 'ROLE_ADMINISTRATOR') {
              console.log('Role=admin');
              Cookies.set('pawloyalty_user_firstname', response.data.administrator.firstname, { expires: 7 });
              Cookies.set('pawloyalty_user_lastname', response.data.administrator.lastname, { expires: 7 });
              Cookies.set('pawloyalty_user_email', response.data.administrator.email, { expires: 7 });
            }
          }

          
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }
  };

  const handleShowPopup = () => {
    setShowCookiePopup(true);
  };

  return (
    <>
      {showCookiePopup && (
        <div
          className="p-6 bg-white rounded-lg shadow-md max-w-88 fixed bottom-5 left-5 mr-5 border-[3px] border-double border-[#057D4B]"
          style={{
            zIndex: 9999,
            transition: "transform 0.5s ease-out",
            transform: "translateY(0)"
          }}
        >
          <div className="relative w-16 mx-auto mb-6 -mt-14">
            <img className="-mt-1" src="/cookie.svg" alt="cookie" />
          </div>
          <span className="block w-full mb-3 leading-normal text-gray-800 sm:w-80 text-sm">
          Om de beste ervaringen te bieden, gebruiken wij technologieën zoals cookies om informatie over je apparaat op te slaan en/of te raadplegen.
          </span>
          <div className="flex justify-between flex-wrap gap-4 flex-col">
            <div className="flex gap-4 flex-wrap flex-col-reverse xxs:flex-nowrap xxs:flex-row">
              <button
                type="button"
                onClick={handleRefuse}
                className="py-2 px-4 bg-[#8ac7ae] hover:bg-[#70b497] focus:ring-[#70b497] focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
              >
                Weigeren
              </button>
              <button
                type="button"
                onClick={handleAccept}
                className="py-2 px-4 bg-[#32614c] hover:bg-[#057D4B] focus:ring-[#09AA66] focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
              >
                Accepteren
              </button>
            </div>
            <div className="flex flex-wrap flex-row justify-between">

              <a className="mr-1 text-xs text-gray-600 hover:text-gray-800" href="/cookie-beleid">
                Cookie beleid
              </a>

              <a className="mr-1 text-xs text-gray-600 hover:text-gray-800" href="/PrivacyPolicy" >
                Privacy beleid
              </a>

              <a className="mr-1 text-xs text-gray-600 hover:text-gray-800" href="/AlgemeneVoorwaarden">
                Algemene voorwaarden
              </a>

            </div>
          </div>
        </div>
      )}
      {!showCookiePopup && (
        <button
          className="hidden md:inline fixed bottom-0 left-5 px-3 py-0 bg-white text-[#32614c] rounded-t-lg shadow-md"
          onClick={handleShowPopup}
        >
          🍪 Cookies
        </button>
      )}

      {window.location.pathname === "/navigation-more" && (
        <button onClick={handleShowPopup} id="CookiePopup-Button" className="hidden hover:text-gray-800 hover:bg-gray-100 items-center p-2 my-6 transition-colors duration-200 text-gray-600 border border-[#31C787] rounded-full px-4">
        <img className="h-[20px] w-[20px] min-w-[20px]" src="/cookie.svg" alt="cookies" />
        <span className="mx-4 text-lg font-normal">
            Cookies
        </span>
        <span className="flex-grow text-right">
        </span>
      </button>
      )}
    </>
  );
};

export default CookiesPopup;
        