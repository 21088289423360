// import Image from "../assets/logo.png";

import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { axiosAuth } from '../utils/axiosInterceptor';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  let timeoutId: NodeJS.Timeout;

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsOpen(false);
    }, 300); // 0.3 second delay
  };

  const handleLogout = async () => {
    let logoutURL = '';

    if (role === 'ROLE_CUSTOMER') {
      logoutURL = '/customers/logout';
    } else if (role === 'ROLE_ADMINISTRATOR') {
      logoutURL = '/administrator/logout';
    } else {
      console.error('Unknown role:', role);
      return; // or handle the error accordingly
    }

    try {
      const response = await axiosAuth.post(
        process.env.REACT_APP_BACKEND_URL + logoutURL
      );

      if (response.status === 404) {
        alert(response.statusText);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }

    Cookies.remove('pawloyalty_token');
    Cookies.remove('pawloyalty_role');
    Cookies.remove('pawloyalty_id');
    Cookies.remove('pawloyalty_user_firstname');
    Cookies.remove('pawloyalty_user_lastname');
    Cookies.remove('pawloyalty_user_email');

    navigate('/');
  };
  const token = Cookies.get('pawloyalty_token');
  const role = Cookies.get('pawloyalty_role');
  return (
    <header>
      <section className="text-gray-100 bg-gradient-to-tr from-[#057D4B] via-[#09AA66] to-[#31C787] px-10 min-h-20 flex flex-wrap content-center justify-between">
        <div id="Logo">
          <Link
            to={
              token
                ? role === 'ROLE_ADMINISTRATOR'
                  ? '/admin'
                  : '/client'
                : '/'
            }
          >
            <img src="/logo.png" alt="logo" className="scale-20 w-20" />
          </Link>
        </div>

        {(() => {
          if (
            (token !== undefined && role === 'ROLE_CUSTOMER') ||
            role === 'ROLE_ADMINISTRATOR'
          ) {
            if (role === 'ROLE_CUSTOMER') {
              return (
                <div id="Menu" className="flex flex-wrap gap-x-2 items-center">
                  <Link
                    to="/client"
                    className="transition-all duration-200 hover:text-white hover:font-semibold"
                  >
                    Home
                  </Link>
                  <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
                  <Link
                    to="/pets"
                    className="transition-all duration-200 hover:text-white hover:font-semibold"
                  >
                    Huisdieren
                  </Link>
                  <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
                  <Link
                    to="/contact"
                    className="transition-all duration-200 hover:text-white hover:font-semibold"
                  >
                    Contact
                  </Link>
                </div>
              );
            } else if (role === 'ROLE_ADMINISTRATOR') {
              return (
                <div id="Menu" className="flex flex-wrap gap-x-2 items-center">
                  <Link
                    to="/admin"
                    className="transition-all duration-200 hover:text-white hover:font-semibold"
                  >
                    Home
                  </Link>
                  <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
                  <Link
                    to="/admin/species"
                    className="transition-all duration-200 hover:text-white hover:font-semibold"
                  >
                    Diersoorten
                  </Link>
                  <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
                  <Link
                    to="/admin/breeds"
                    className="transition-all duration-200 hover:text-white hover:font-semibold"
                  >
                    Rassen
                  </Link>
                  <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
                  <Link
                    to="/admin/products"
                    className="transition-all duration-200 hover:text-white hover:font-semibold"
                  >
                    Producten
                  </Link>
                  <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
                  <Link
                    to="/admin/brands"
                    className="transition-all duration-200 hover:text-white hover:font-semibold"
                  >
                    Merken
                  </Link>
                  <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
                  <Link
                    to="/admin/clients"
                    className="transition-all duration-200 hover:text-white hover:font-semibold"
                  >
                    Gebruikers
                  </Link>
                </div>
              );
            }
          } else {
            return (
              <div id="Menu" className="flex flex-wrap gap-x-2 items-center">
                <Link
                  to="/"
                  className="transition-all duration-200 hover:text-white hover:font-semibold"
                >
                  Welkom
                </Link>
                <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
                <Link
                  to="/login"
                  className="transition-all duration-200 hover:text-white hover:font-semibold"
                >
                  Login
                </Link>
                <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
                <Link
                  to="/register"
                  className="transition-all duration-200 hover:text-white hover:font-semibold"
                >
                  Register
                </Link>
                <span className="w-[2px] bg-white/30 rounded-lg m-2 opacity-70 h-4"></span>
                <Link
                  to="/contact"
                  className="transition-all duration-200 hover:text-white hover:font-semibold"
                >
                  Contact
                </Link>
              </div>
            );
          }
        })()}

        {(() => {
          if (token !== undefined) {
            return (
              <div id="Dropdown" className="relative inline-block self-center	">
                <button
                  id="dropdownAvatarNameButton"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="flex items-center text-sm pe-1 font-medium text-gray-900 rounded-full hover:text-blue-600 md:me-0 focus:ring-4 focus:ring-gray-100"
                  type="button"
                >
                  <span className="sr-only">Open user menu</span>
                  {Cookies.get('pawloyalty_AcceptCookies') === 'true' ? (
                    <>
                      <span className="text-white">
                        {Cookies.get('pawloyalty_user_firstname')}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-white">Account</span>
                    </>
                  )}
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {/* Dropdown menu */}
                {isOpen && (
                  <div
                    id="dropdownAvatarName"
                    className="z-10 absolute top-full mt-1 right-1 w-44 bg-white divide-y divide-gray-100 rounded-lg shadow"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="px-4 py-3 text-sm text-gray-900">
                      <div className="font-medium">
                        {Cookies.get('pawloyalty_AcceptCookies') === 'true' ? (
                          <>
                            {Cookies.get('pawloyalty_user_firstname')}{' '}
                            {Cookies.get('pawloyalty_user_lastname')}
                          </>
                        ) : (
                          <>Mijn account</>
                        )}
                      </div>
                      <div className="truncate">
                        {Cookies.get('pawloyalty_AcceptCookies') === 'true' ? (
                          <>{Cookies.get('pawloyalty_user_email')}</>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <ul
                      className="py-2 text-sm text-gray-700"
                      aria-labelledby="dropdownInformdropdownAvatarNameButton"
                    >
                      {Cookies.get('pawloyalty_role') === 'ROLE_CUSTOMER' ? (
                        <li>
                          <a
                            href="/profile"
                            className="block px-4 py-2 hover:bg-gray-100 "
                          >
                            Mijn profiel
                          </a>
                        </li>
                      ) : (
                        <li>
                          <a
                            href="/admin/profile"
                            className="block px-4 py-2 hover:bg-gray-100 "
                          >
                            Mijn profiel
                          </a>
                        </li>
                      )}
                    </ul>
                    <div className="py-2">
                      <a
                        onClick={handleLogout}
                        className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row"
                      >
                        <img src="/logout.svg" alt="logo" className="w-5" />
                        Log uit
                      </a>
                    </div>
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <div className="self-center">
                <Link
                  to="/login"
                  className="transition-all duration-200 hover:text-white hover:font-semibold"
                >
                  Login
                </Link>
              </div>
            );
          }
        })()}
      </section>

      <div className="bg-gradient-to-r from-[#057D4B] to-[#0BAB67]">
        <div className="h-[25px] rounded-t-[100px] bg-background"></div>
      </div>
    </header>
  );
};

export default Header;
