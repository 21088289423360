const CientOnzeWinkels = () => {
return (
<section id='OnzeWinkels'>
            <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                <div className="mt-4 md:mt-0">
                    <h2 className="mb-4 text-2xl font-bold text-gray-90 text-center">Waar kunt u onze winkels vinden?</h2>
                    <div className="mb-6 font-light text-gray-500 md:text-lg">
                        <ul className='list-disc pl-10'>
                            <li className='marker:text-[#079559]'>Zwolle</li>
                            <li className='marker:text-[#079559]'>Steenwijk</li>
                            <li className='marker:text-[#079559]'>Leeuwarden</li>
                            <li className='marker:text-[#079559]'>Utrecht</li>
                            <li className='marker:text-[#079559]'>Groningen</li>
                            <li className='marker:text-[#079559]'>Haarlem</li>
                            <li className='marker:text-[#079559]'>Zoutelande</li>
                            <li className='marker:text-[#079559]'>'s-Hertogenbosch</li>
                            <li className='marker:text-[#079559]'>Doetinchem</li>
                            <li className='marker:text-[#079559]'>Zoetemeer</li>
                        </ul>
                    </div>
                </div>
                <img className="w-auto max-h-[500px] justify-self-end" src="/WaarZittenOnzeWinkels.webp" alt="Kaart met waar onze winkels zitten"/>
            </div>
        </section>
)
}

export default CientOnzeWinkels;