import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link } from "react-router-dom";


// Define an interface for the shape of the customer object
interface Customer {
    // Define properties based on the actual structure of your customer object
    active: boolean; // Assuming 'active' is a boolean property
    // Add other properties as needed
}

const UserAmount = () => {
    const [UserAmount, setUserAmount] = useState<number | null>(null);
    const [UserAmountActive, setUserAmountActive] = useState<number | null>(null);
    const [UserAmountNonActive, setUserAmountNonActive] = useState<number | null>(null);
    const [BrandAmount, setBrandAmount] = useState<number | null>(null);
    const [BreedsAmount, setBreedsAmount] = useState<number | null>(null);
    const [SpeciesAmount, setSpeciesAmount] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const user_token = Cookies.get('pawloyalty_token');
            const user_role = Cookies.get('pawloyalty_role');

            if (user_token && user_role === 'ROLE_ADMINISTRATOR') {
                try {
                    const response = await axios.get(`http://127.0.0.1:8000/api/administrator/customers/all`, {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${user_token}`,
                        },
                    });

                    const BrandResponse = await axios.get(`http://127.0.0.1:8000/api/brands`, {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${user_token}`,
                        },
                    });
                    setBrandAmount(BrandResponse.data.data.length);

                    const BreedsResponse = await axios.get(`http://127.0.0.1:8000/api/breeds`, {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${user_token}`,
                        },
                    });
                    setBreedsAmount(BreedsResponse.data.data[0].length);

                    const SpeciesResponse = await axios.get(`http://127.0.0.1:8000/api/species`, {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${user_token}`,
                        },
                    });
                    setSpeciesAmount(SpeciesResponse.data.data.length);

                    if (response.status === 404) {
                        alert(response.statusText);
                    } else {
                        // Filter active accounts and set the UserAmountActive state
                        setUserAmount(response.data.customers.length);

                        // Filter active accounts and set the UserAmountActive state
                        const activeAccounts = response.data.customers.filter((customer: Customer) => customer.active);
                        setUserAmountActive(activeAccounts.length);

                        const NonActiveAccounts = response.data.customers.filter((customer: Customer) => !customer.active);
                        setUserAmountNonActive(NonActiveAccounts.length);
                        setLoading(false); // Update loading state
                    }
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                    setLoading(false); // Update loading state
                }
            }
        };

        fetchData();
    }, []);

    return (
        <div className='flex flex-wrap flex-col gap-4'>
            <div className='flex flex-wrap gap-4'>
            <Link to="/admin/clients" className="p-4 bg-white shadow-lg rounded-2xl w-[175px] hover:scale-[103%] transition-all duration-150 cursor-pointer">
                <div className="flex items-center">
                    <span className="relative p-3 bg-green-200 rounded-xl">
                        <img className="w-auto h-[20px]" src="/user-icon.svg" alt="Icon for users" />
                    </span>
                    <p className="ml-2 text-black text-md">
                        Actieve Gebruikers
                    </p>
                </div>
                <div className="flex flex-col justify-start">
                    <p className="my-4 text-4xl font-bold text-left text-gray-700">
                        {loading ? '...' : (UserAmountActive !== null ? UserAmountActive : 'No Data')}
                    </p>
                </div>
            </Link>

            <Link to="/admin/clients" className="p-4 bg-white shadow-lg rounded-2xl w-[175px] hover:scale-[103%] transition-all duration-150 cursor-pointer">
                <div className="flex items-center">
                    <span className="relative p-3 bg-orange-200 rounded-xl">
                        <img className="w-auto h-[20px]" src="/user-icon.svg" alt="Icon for users" />
                    </span>
                    <p className="ml-2 text-black text-md">
                        Niet-actieve Gebruikers
                    </p>
                </div>
                <div className="flex flex-col justify-start">
                    <p className="my-4 text-4xl font-bold text-left text-gray-700">
                        {loading ? '...' : (UserAmountNonActive !== null ? UserAmountNonActive : 'No Data')}
                    </p>
                </div>
            </Link>

            <Link to="/admin/clients" className="p-4 bg-white shadow-lg rounded-2xl w-[175px] hover:scale-[103%] transition-all duration-150 cursor-pointer">
                <div className="flex items-center">
                    <span className="relative p-3 bg-blue-200 rounded-xl">
                        <img className="w-auto h-[20px]" src="/user-icon.svg" alt="Icon for users" />
                    </span>
                    <p className="ml-2 text-black text-md">
                        Alle Gebruikers
                    </p>
                </div>
                <div className="flex flex-col justify-start">
                    <p className="my-4 text-4xl font-bold text-left text-gray-700">
                        {loading ? '...' : (UserAmount !== null ? UserAmount : 'No Data')}
                    </p>
                </div>
            </Link>
        </div>

        <div className='flex flex-wrap gap-4'>
            <Link to="/admin/species" className="p-4 bg-white shadow-lg rounded-2xl w-[175px] hover:scale-[103%] transition-all duration-150 cursor-pointer">
                <div className="flex items-center">
                    <span className="relative p-3 bg-yellow-100 rounded-xl">
                        <img className="w-auto h-[20px] opacity-75" src="/dog-black.svg" alt="dog icon" />
                    </span>
                    <p className="ml-2 text-black text-md">
                        Dier soorten
                    </p>
                </div>
                <div className="flex flex-col justify-start">
                    <p className="my-4 text-4xl font-bold text-left text-gray-700">
                        {loading ? '...' : (SpeciesAmount !== null ? SpeciesAmount : 'No Data')}
                    </p>
                </div>
            </Link>

            <Link to="/admin/breeds" className="p-4 bg-white shadow-lg rounded-2xl w-[175px] hover:scale-[103%] transition-all duration-150 cursor-pointer">
                <div className="flex items-center">
                    <span className="relative p-3 bg-yellow-100 rounded-xl">
                        <img className="w-auto h-[20px] opacity-75" src="/dog-black.svg" alt="dog icon" />
                    </span>
                    <p className="ml-2 text-black text-md">
                        Rassen
                    </p>
                </div>
                <div className="flex flex-col justify-start">
                    <p className="my-4 text-4xl font-bold text-left text-gray-700">
                        {loading ? '...' : (BreedsAmount !== null ? BreedsAmount : 'No Data')}
                    </p>
                </div>
            </Link>

            <Link to="/admin/brands" className="p-4 bg-white shadow-lg rounded-2xl w-[175px] hover:scale-[103%] transition-all duration-150 cursor-pointer">
                <div className="flex items-center">
                    <span className="relative p-3 bg-yellow-100 rounded-xl">
                        <img className="w-auto h-[20px] opacity-75" src="/dog-black.svg" alt="dog icon" />
                    </span>
                    <p className="ml-2 text-black text-md">
                        Merken
                    </p>
                </div>
                <div className="flex flex-col justify-start">
                    <p className="my-4 text-4xl font-bold text-left text-gray-700">
                        {loading ? '...' : (BrandAmount !== null ? BrandAmount : 'No Data')}
                    </p>
                </div>
            </Link>
        </div>
        </div>
    );
};

export default UserAmount;
