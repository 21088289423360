import React, { useState } from 'react';

interface ContactProps {}

const ContactForm: React.FC<ContactProps> = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(formData);
    };

    return (
        <div className="py-2 px-4 mx-auto max-w-screen-md">
            <h2 className="mb-4 text-2xl font-bold text-gray-90">Neem contact op</h2>
            <p className="mb-4 font-light text-left text-gray-500 sm:text-xl">
                Heeft u een probleem? Wilt u feedback sturen? Meer informatie nodig over een aanbieding? Laat het ons weten.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-row">
                    <div className="w-1/2 pr-2">
                        <label htmlFor="firstName" className="block my-2 text-left text-sm font-medium text-gray-900">
                            Voornaam
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            placeholder="Voer uw voornaam in"
                            required
                        />
                    </div>
                    <div className="w-1/2 pl-2">
                        <label htmlFor="lastName" className="block my-2 text-left text-sm font-medium text-gray-900">
                            Achternaam
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            placeholder="Voer uw achternaam in"
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="email" className="block my-2 text-left text-sm font-medium text-gray-900">
                        Email adres
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        placeholder="mail@domein.nl"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="subject" className="block my-2 text-left text-sm font-medium text-gray-900">
                        Onderwerp
                    </label>
                    <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm"
                        placeholder="Welk probleem/suggestie heeft u?"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="message" className="block my-2 text-left text-sm font-medium text-gray-900">
                        Je bericht
                    </label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows={6}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300"
                        placeholder="Vraag/suggestie..."
                    />
                </div>
                <button
                    type="submit"
                    className="mt-2 p-2 float-right text-white rounded-lg border-green-600 bg-green-600 hover:scale-105"
                >
                    Verstuur
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
