import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Pet } from '../../../utils/interfaces';

export default function Pets() {

    const [pets, setPets] = useState<Pet[]>([]);

    useEffect(() => {
        axios.get('http://localhost:8000/api/pets', {
            headers: {
                'Authorization': `Bearer ${Cookies.get('pawloyalty_token')}`
            }
        })
            .then(response => {
                setPets(response.data.data);
                // console.log(pets.products?.brand.name);
            })
            .catch(error => console.error(error));
    }, []);

    return (
        <div className="flex flex-col">
            <p className="text-black text-bold text-3xl pb-5">Mijn Huisdieren</p>
            <div className=" flex flex-row gap-5">
                <div className="border border-black rounded-lg p-5 text-black w-64">
                    <p className="text-lg font-bold">
                        {pets.length}
                    </p>
                    <p className="text-sm font-semibold">Huisdieren</p>
                </div>
                <div className="border border-black rounded-lg p-5 text-black w-64">
                    <a href="/pets/add" className="text-sm font-semibold flex justify-center p-[10%]">Huisdier toevoegen</a>
                </div>
            </div>


            <div className="inline-flex items-center justify-center w-full">
                <hr className="w-full h-px my-8 bg-gray-200 border-0" />
                <span className="absolute px-3 text-2xl font-medium text-gray-500 -translate-x-1/2 -translate-y-1 bg-background left-1/2">
                    Huisdieren
                </span>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Naam
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Soort
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Ras
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Voer
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Edit
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {pets.map((pet, index) => (
                            <tr key={index} className="odd:bg-white even:bg-gray-50">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {pet.name}
                                </th>
                                <td className="px-6 py-4">
                                    {pet.specie?.name}
                                </td>
                                <td className="px-6 py-4">
                                    {pet.breed?.name}
                                </td>
                                <td className="px-6 py-4">
                                {pet.products ? pet.products.map(product => product.brand.name).join(', ') : ''} - {pet.products ? pet.products.map(product => product.name).join(', ') : ''}
                                </td>
                                <td className="px-6 py-4">
                                    <Link to={"/pets/edit/"+ pet.id} className="text-indigo-600 hover:text-indigo-900">
                                        Edit
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
