import Header from '../components/Header';
import Footer from '../components/Footer';
import HeaderMobile from '../components/Header-mobile';
import MobileNavbar from '../components/Mobile-Navigation';
import { useMediaQuery } from '@react-hook/media-query';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CookiesPopup from "../components/Cookie-popup";
import React, { useState } from "react";
import { axiosAuth } from '../utils/axiosInterceptor';


const NavigationMore = () => {
  const navigate = useNavigate();
  const token = Cookies.get('pawloyalty_token');
  const role = Cookies.get('pawloyalty_role');

  const handleLogout = async () => {
    let logoutURL = '';

    if (role === 'ROLE_CUSTOMER') {
      logoutURL = '/customers/logout';
    } else if (role === 'ROLE_ADMINISTRATOR') {
      logoutURL = '/administrator/logout';
    } else {
      console.error('Unknown role:', role);
      return; // or handle the error accordingly
    }

    try {
      const response = await axiosAuth.post(
        process.env.REACT_APP_BACKEND_URL + logoutURL
      );

      if (response.status === 404) {
        alert(response.statusText);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }

    Cookies.remove('pawloyalty_token');
    Cookies.remove('pawloyalty_role');
    Cookies.remove('pawloyalty_id');
    Cookies.remove('pawloyalty_user_firstname');
    Cookies.remove('pawloyalty_user_lastname');
    Cookies.remove('pawloyalty_user_email');

    navigate('/');
  };

  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <div className="w-full">
        {isMobile ? <HeaderMobile /> : <Header />}
      </div>

      <div className="inline-flex items-center justify-center w-full">
        <hr className="w-full h-px my-8 bg-gray-200 border-0" />
        <span className="absolute px-3 text-2xl font-medium text-gray-500 -translate-x-1/2 -translate-y-1 bg-background left-1/2">
          Meer links
        </span>
      </div>

      
<div className="relative navigation-more-class">
    <div className="flex flex-col sm:flex-row sm:justify-around">
        <div className="h-screen w-72">
            <nav className="mt-10 px-6 ">
              {(() => {
                if (
                  (token !== undefined && role === 'ROLE_CUSTOMER') ||
                  role === 'ROLE_ADMINISTRATOR'
                ) {
                  if (role === 'ROLE_CUSTOMER') {
                    return (
                      <>
                        <a href="/client" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <svg className="w-5 h-5 mb-1 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                          <span className="mx-4 text-lg font-normal">
                              Home
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>
                        <a href="/pets" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <img className="h-[20px] w-[20px] min-w-[20px]" src="/dog-black.svg" alt="huisdieren" />
                          <span className="mx-4 text-lg font-normal">
                              Huisdieren
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>
                        <a href="/profile" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <svg className="w-5 h-5 mb-1 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                            </svg>
                          <span className="mx-4 text-lg font-normal">
                              Profiel
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>
                        <a href="/contact" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <img className="h-[20px] w-[20px] min-w-[20px]" src="/contact-black.svg" alt="Contact" />
                          <span className="mx-4 text-lg font-normal">
                              Contact
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>

                        <CookiesPopup />

                        <button onClick={handleLogout} className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg border border-[#057D4B] rounded-full px-4">
                          <img className="h-[20px] w-[20px] min-w-[20px]" src="/logout.svg" alt="Logout" />
                          <span className="mx-4 text-lg font-normal">
                              Log uit
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </button>
                      </>
                    );
                  } else if (role === 'ROLE_ADMINISTRATOR') {
                    return (
                      <>
                        <a  href="/admin" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <svg className="w-5 h-5 mb-1 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                          <span className="mx-4 text-lg font-normal">
                              Dashboard
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>
                        <a  href="/admin/species" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <img className="h-[20px] w-[20px] min-w-[20px]" src="/dog-black.svg" alt="producten" />
                          <span className="mx-4 text-lg font-normal">
                              Diersoorten
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>
                        <a  href="/admin/breeds" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <img className="h-[20px] w-[20px] min-w-[20px]" src="/dog-black.svg" alt="producten" />
                          <span className="mx-4 text-lg font-normal">
                              Rassen
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>
                        <a  href="/admin/products" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <img className="h-[20px] w-[20px] min-w-[20px]" src="/products-black.svg" alt="producten" />
                          <span className="mx-4 text-lg font-normal">
                              Producten
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>
                        <a  href="/admin/brands" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <img className="h-[20px] w-[20px] min-w-[20px]" src="/brands-black.svg" alt="producten" />
                          <span className="mx-4 text-lg font-normal">
                              Merken
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>
                        <a  href="/admin/clients" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <img className="h-[20px] w-[20px] min-w-[20px]" src="/users-black.svg" alt="users" />
                          <span className="mx-4 text-lg font-normal">
                              Gebruikers
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>
                        <a  href="/admin/profile" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                          <svg className="w-5 h-5 mb-1 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                            </svg>
                          <span className="mx-4 text-lg font-normal">
                              Profiel
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </a>

                        <CookiesPopup />

                        <button onClick={handleLogout} className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg border border-[#057D4B] rounded-full px-4">
                          <img className="h-[20px] w-[20px] min-w-[20px]" src="/logout.svg" alt="Logout" />
                          <span className="mx-4 text-lg font-normal">
                              Log uit
                          </span>
                          <span className="flex-grow text-right">
                          </span>
                        </button>
                      </>
                    );
                  }
                } else {
                  return (
                    <>
                    <a href="/" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                      <img className="h-[20px] w-[20px] min-w-[20px]" src="/welcome-black.svg" alt="Welkom" />
                      <span className="mx-4 text-lg font-normal">
                          Welkom
                      </span>
                      <span className="flex-grow text-right">
                      </span>
                    </a>
                    <a href="/login" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                      <img className="h-[20px] w-[20px] min-w-[20px]" src="/login-black.svg" alt="Login" />
                      <span className="mx-4 text-lg font-normal">
                          Login
                      </span>
                      <span className="flex-grow text-right">
                      </span>
                    </a>
                    <a href="/register" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                      <img className="h-[20px] w-[20px] min-w-[20px]" src="/register-black.svg" alt="Register" />
                      <span className="mx-4 text-lg font-normal">
                          Register
                      </span>
                      <span className="flex-grow text-right">
                      </span>
                    </a>
                    <a href="/contact" className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600rounded-lg rounded-full">
                      <img className="h-[20px] w-[20px] min-w-[20px]" src="/contact-black.svg" alt="Contact" />
                      <span className="mx-4 text-lg font-normal">
                          Contact
                      </span>
                      <span className="flex-grow text-right">
                      </span>
                    </a>


                    <CookiesPopup />
                  </>
                  );
                }
              })()}
            </nav>
        </div>
    </div>
</div>

      
      
      <div className="w-full">
        {isMobile ? <MobileNavbar /> : <Footer />}
        {isMobile && <div className='h-[75px]'></div>}
      </div>
    </>
  );
};

export default NavigationMore;
