import { useState, useEffect } from 'react';
import { axiosAuth } from '../../../utils/axiosInterceptor';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AutoComplete from '../../../components/AutoComplete';

interface Customer {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  active: number;
  // Other properties...
}

export default function Customers() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);

  const [data, setData] = useState<Customer[]>([]);

  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + '/administrator/customers/all')
      .then((response) => {
        const activeCustomers = response.data.customers.filter((customer: Customer) => customer.active === 1);
        setCustomers(activeCustomers);

        if (activeCustomers.length > 0) {
          setData(prevState => [
            ...prevState,
            ...activeCustomers.map((customer: any) => ({
              id: customer.id,
              firstname: customer.firstname,
              lastname: customer.lastname,
              email: customer.email,
              phone: customer.phone,
              get name() {
                return `${this.firstname} ${this.lastname}`;
              },
            }))
          ]);
        }
      });
  }, []);

  function deactivateCustomer(id: number) {
    axiosAuth
      .put(process.env.REACT_APP_BACKEND_URL + '/administrator/customers/' + id + '/deactivate')
      .then((response) => {
        setCustomers(customers.filter((customers: any) => customers.id !== id));
      });
  }
  function anonymizeCustomer(id: number) {
    axiosAuth
      .put(process.env.REACT_APP_BACKEND_URL + '/administrator/customers/' + id + '/anonymize')
      .then((response) => {
        setCustomers(customers.filter((customers: any) => customers.id !== id));
      });
  }

  const [value, setValue] = useState();

  const onSelect = (item: any) => {
    setValue(typeof item === 'string' ? { name: item } : item);
    if (typeof item === 'string' || !item.id) return;
    navigate(`/admin/clients/profile/${item.id}`);
  };


  return (
    <div className="flex flex-col">
      <div className="flex gap-2 mb-5">
        <p className="text-black text-bold text-3xl mr-4">Gebuikers</p>
        <div className='flex flex-wrap gap-2'>
          <Link
            to="/admin/clients/deactivated"
            className="bg-primary rounded-full px-4 py-2 text-white hover:bg-[#16B471] mr-3"
          >
            Gedeactiveerde accounts
          </Link>
        </div>

      </div>

      <div className="flex flex-col gap-8 mb-6">
        <AutoComplete placeholder={"Zoek klant..."} data={data} onChange={(item: any) => onSelect(item)} value={value} />
      </div>

      {customers.length > 0 ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg min-w">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Voornaam
                </th>
                <th scope="col" className="px-6 py-3">
                  Achternaam
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  View
                </th>
                <th scope="col" className="px-6 py-3">
                  Anonymize 
                </th>
                <th scope="col" className="px-6 py-3">
                  Deactivate
                </th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer: any) => (
                <tr className="odd:bg-white even:bg-gray-50">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {customer.firstname}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {customer.lastname}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {customer.email}
                  </th>
                  <td className="px-6 py-4">
                    <Link
                      to={'/admin/clients/profile/' + customer.id}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      View
                    </Link>
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => anonymizeCustomer(customer.id)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Anonymize
                    </button>
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => deactivateCustomer(customer.id)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Deactivate
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
