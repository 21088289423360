import { useEffect, useState } from 'react';

export default function AutoComplete(props: any) {
  const { placeholder, data, onChange, value } = props;

  const [selectedItem, setSelectedItem] = useState<{name?: string}>({});
  const [filteredData, setFilteredData] = useState(data);

  // if the data changes, update the filtered data
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  // if the value changes, update the selected item and filter the data based on the new value
  useEffect(() => {
    setSelectedItem(typeof value === 'string' ? { name: value } : value);
    setFilteredData(filterItems(value?.name || ''));
  }, [value]);

  const [isFocused, setIsFocused] = useState(false);

  // if the input changes, update the selected item and filter the data based on the new input
  const handleInputChange = (event: any) => {
    onChange(event.target.value);
    setSelectedItem(event.target.value);
    setFilteredData(filterItems(event.target.value));
  };

  // if an item is selected, update the selected item and filter the data based on the new item
  const handleItemSelection = (item: any) => {
    onChange(item);
    setSelectedItem(item);
    setFilteredData(filterItems(item.name));
  };

  // filter the data based on the query
  const filterItems = (query: any) => {
    return data.filter((item: any) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
  }

  return (
    <div>
      <label
        htmlFor="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 ps-10 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          placeholder={placeholder}
          value={value?.name || selectedItem?.name || ''}
          required
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={handleInputChange}
          disabled={!data || data.length === 0}
        />
      </div>

      {isFocused && filteredData.length > 0 && (
        <ul className="absolute z-10 mt-1 min-w-64 overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredData.map((item: any) => (
            <li
              key={item}
              className="relative py-2 pl-3 pr-9 cursor-pointer select-none hover:bg-gray-100"
              onMouseDown={() => handleItemSelection(item)}
            >
              <div className="flex items-center space-x-3">
                <span className="font-normal block truncate">{item.name}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
