import { useEffect, useState } from 'react';
import AutoComplete from '../AutoComplete';
import { Brand, Breed, Product, Specie } from '../../utils/interfaces';

export default function Pet(props: any) {
  const { petId = 0, species, data, onChange } = props;

  const [form, setForm] = useState<{
    name?: string;
    species?: Specie;
    breed?: Breed;
    brand?: Brand;
    product?: Product;
    birthDate?: Date;
  }>(data);

  useEffect(() => {
    setForm(data);
  }, [data]);

  const [breeds, setBreeds] = useState<Breed[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  // send the data to the parent component when the form changes
  useEffect(() => {
    if (!form) return;
    onChange(form, petId);
  }, [form, petId]);

  // set the species, breeds, brands and products when the species changes
  const onSpeciesChange = (item: any) => {
    setBreeds(item.breeds || []);
    setBrands(item.brands || []);
    setForm({
      ...form,
      species: {
        id: item.id,
        name: item.name || item || '',
      },
    });
  };

  // set the breed when the breed changes
  const onBreedChange = (item: Breed) => {
    setForm({
      ...form,
      breed: item,
    });
  };

  // set the brand and products when the brand changes
  const onBrandChange = (item: Brand) => {
    setProducts(item.products || []);
    setForm({
      ...form,
      brand: {
        id: item.id,
        name: item.name,
      },
    });
  };

  // set the product when the product changes
  const onProductChange = (item: any) => {
    setForm({
      ...form,
      product: item,
    });
  };

  // set the name when the name changes
  const onNameChange = (event: any) => {
    setForm({
      ...form,
      name: event.target.value,
    });
  };

  // set the birthdate when the birthdate changes
  const onDateChange = (date: Date) => {
    setForm({
      ...form,
      birthDate: date,
    });
  };

  return (
    <div className="flex flex-col gap-4 w-full">

      <form className="w-96">
        <div>
          <label htmlFor="name" className="block mb-2 text-sm text-gray-600">
            Naam
          </label>
          <input
            id="name"
            type="text"
            className="block mb-4 disabled:bg-gray-300 disabled:cursor-not-allowed w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
            placeholder="Naam"
            required
            onChange={onNameChange}
            value={form.name || ''}
          />
        </div>
        <div className="mb-4">
          <AutoComplete
            placeholder="Selecteer een dier"
            data={species}
            onChange={(item: any) => onSpeciesChange(item)}
            value={form.species}
          />
        </div>
        <div className="mb-4">
          <AutoComplete
            placeholder="Selecteer een ras"
            data={breeds}
            onChange={(item: any) => onBreedChange(item)}
            value={form.breed}
          />
        </div>

        <h5 className="mb-3">Voeding</h5>
        <div className="mb-4">
          <AutoComplete
            placeholder="Selecteer een merk"
            data={brands}
            onChange={(item: any) => onBrandChange(item)}
            value={form.brand}
          />
        </div>
        <div className="mb-4">
          <AutoComplete
            placeholder="Selecteer een product"
            data={products}
            onChange={(item: any) => onProductChange(item)}
            value={form.product}
          />
        </div>

        <div>
          <label
            htmlFor="birthDate"
            className="block mb-2 text-sm text-gray-600"
          >
            Geboortedatum
          </label>
          <input
            type="date"
            id="birthDate"
            name="birthDate"
            className="block w-full p-3 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
            required
            value={form.birthDate?.toISOString().split('T')[0] || ''}
            onChange={(event) => onDateChange(new Date(event.target.value))}
            max={new Date().toISOString().split('T')[0]}
          />
        </div>
      </form>
    </div>
  );
}
