import Header from './components/Header';
import HeaderMobile from './components/Header-mobile';
import Footer from './components/Footer';
import MobileNavbar from './components/Mobile-Navigation';
import { useMediaQuery } from '@react-hook/media-query';
import { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import {
  adminRoutes,
  clientRoutes,
  VisitorRoutes,
  publicRoutes,
} from './routes';
import Cookies from 'js-cookie';

const token = Cookies.get('pawloyalty_token');

const Base = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <div className="w-full">{isMobile ? <HeaderMobile /> : <Header />}</div>
      <div className="py-16 px-8 sm:py-px-16 md:py-px-24 lg:px-32 flex-1 w-full bg-background">
        <Outlet />
      </div>
      <div className="w-full">
        {isMobile ? <MobileNavbar /> : <Footer />}
        {isMobile && <div className="h-[75px]"></div>}
      </div>
    </>
  );
};

export default function App() {
  const role = Cookies.get('pawloyalty_role');

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="min-h-screen overflow-x-hidden flex flex-col items-center w-full bg-background">
        <Routes>
          {publicRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          ))}

          {(() => {
            if (
              (token !== undefined && role === 'ROLE_CUSTOMER') ||
              role === 'ROLE_ADMINISTRATOR'
            ) {
              if (role === 'ROLE_CUSTOMER') {
                return (
                  <Route element={<Base />}>
                    {clientRoutes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.component}
                      />
                    ))}
                  </Route>
                );
              } else if (role === 'ROLE_ADMINISTRATOR') {
                return (
                  <Route element={<Base />}>
                    {adminRoutes.map((route) => (
                      <Route
                        key={route.path}
                        path={'/admin' + route.path}
                        element={route.component}
                      />
                    ))}
                  </Route>
                );
              }
            } else {
              return VisitorRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.component}
                />
              ));
            }
          })()}
        </Routes>
      </div>
    </Suspense>
  );
}
