import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { axiosAuth } from '../../../utils/axiosInterceptor';


export default function EditProfile() {
  const [errorMessage] = useState("");
  
  const navigate = useNavigate();
  const [kaart] = useState('2401160930');

  const [form, setForm] = useState({
    cardnumber: '',
  });

  useEffect(() => {
      const fetchData = async () => {
      const user_id = Cookies.get('pawloyalty_id');
      const user_token = Cookies.get('pawloyalty_token');

      if (user_id && user_token) {
        try {
          const response = await axios.get(`http://127.0.0.1:8000/api/customers/${user_id}/cards/add-card`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${user_token}`,
            },
          });

          if (response.status === 200) {
            console.log(response.data.customer);

            setForm({
              cardnumber: response.data.customer.cardnumber,
            });
          } else {
            alert(response.statusText);
          }
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, []); // Empty dependency array to ensure this effect runs only once

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^\d*$/)) {
        setForm({
      ...form,
      [e.target.name]: e.target.value,
      });
    }

  };
  

  const onSubmit = async () => {
    
  
      try {
        console.log("2401160930" + form.cardnumber)
        const response = await axiosAuth.post(
          `${process.env.REACT_APP_BACKEND_URL}/customers/${Cookies.get('pawloyalty_id')}/cards/add-card/`,
          {
            cardnumber: ("2401160930" + form.cardnumber),
            customer_id: Cookies.get('pawloyalty_id'),
          },
        )
        console.log(form.cardnumber);
  
        if (response.status === 201) {
          console.log('Card added successfully');
          navigate('/profile');
        } else {
          alert('Failed to add card: ' + response.statusText);
          console.log(form.cardnumber);
        }
      } catch (error) {
        console.error('There was a problem adding the card:', error);
      }
    navigate('/profile');
  };

  return (
    <div className="flex flex-col gap-8">
      <h1>Kaart Koppelen</h1>
      
    <div className="flex relative ">
    <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
        {kaart}
    </span>
    <input value={form.cardnumber} autoComplete='off' maxLength={4} onChange={onChange} type="text" id="cardnumber" className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent" name="cardnumber" placeholder="Kaartnummer"/>
    {errorMessage && <span className="text-red-500">{errorMessage}</span>}
    </div>

      <div className='flex flex-row'>
      <button
            onClick={() => navigate(-1)}
            className="bg-primary rounded-full px-4 py-2 text-sm text-white hover:bg-[#16B471] mr-3 flex items-center justify-center w-24"
        >
            <img className="h-[20px] w-[20px] min-w-[20px] mr-2" src="/back-square.svg" alt="go back" />
            Terug
        </button>
        <button
          onClick={onSubmit}
          className="px-6 py-2 text-sm text-white bg-primary rounded-full hover:bg-[#16B471]"
        >
          Opslaan
        </button>
      </div>
    </div>
  );
}
