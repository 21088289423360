import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { axiosAuth } from '../../../utils/axiosInterceptor';
import { Brand, Breed, Product, Specie } from '../../../utils/interfaces';
import Pet from '../../../components/client/Pet';
import Cookies from 'js-cookie';

export default function EditPet() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [species, setSpecies] = useState<Specie[]>([]);

  const [form, setForm] = useState<{
    name?: string;
    species?: Specie;
    breed?: Breed;
    brand?: Brand;
    product?: Product;
    birthDate?: Date;
  }>();

  // Get all species
  useEffect(() => {
    const getData = async () => {
      const speciesData: Specie[] = await axiosAuth
        .get(process.env.REACT_APP_BACKEND_URL + '/brand-species/products')
        .then((response) => {
          setSpecies(response.data.data);
          return response.data.data;
        });

      await axiosAuth
        .get(process.env.REACT_APP_BACKEND_URL + '/pets/find/' + id)
        .then((res) => {
          const data = res.data.data[0];
          
          // Find the current species
          const currSpecies: Specie = speciesData.find(
            (s) => s.id === data.species_id
          ) as Specie;

          // Find the current breed
          const currBreed: Breed | undefined = currSpecies?.breeds?.find(
            (b) => b.id === data.breed_id
          );

          // Find the current brand
          const currBrand: Brand | undefined = currSpecies?.brands?.find(
            (b) => b.id === data.products[0]?.brand?.id
          );

          // Find the current product
          const currProduct: Product | undefined = currBrand?.products?.find(
            (p) => p.id === data.products[0]?.id
          );

          // Set the form
          setForm({
            name: data.name,
            species: currSpecies,
            breed: currBreed,
            brand: currBrand,
            product: currProduct,
            birthDate: new Date(data.date_of_birth),
          });
        });
    };

    getData();
  }, [id]);

  // send the data to the parent component when the form changes
  const onChange = (form: {
    name?: string;
    species?: Specie;
    breed?: Breed;
    brand?: Brand;
    product?: Product;
    birthDate?: Date;
  }) => {
    setForm(form);
  };

  // submit the form
  const onSubmit = () => {
    axiosAuth.put(process.env.REACT_APP_BACKEND_URL + '/pets/update/' + id, {
      customer_id: Cookies.get('pawloyalty_id'),
      species_id: form?.species?.id,
      breed_id: form?.breed?.id,
      name: form?.name,
      date_of_birth: form?.birthDate?.toISOString().split('T')[0],
    }).then((res) => {
      console.log(res);
    });

    navigate('/pets');
  };

  return (
    <div className="flex flex-col gap-8">
      <h1>Huisdier Bewerken</h1>

      {species.length === 0 || !form ? (
        <div>Loading...</div>
      ) : (
        <>
          <Pet species={species} data={form ?? {}} onChange={onChange} />
          <div>
          <button
              onClick={() => navigate(-1)}
              className="bg-primary rounded-full px-4 py-2 text-white hover:bg-[#16B471] mr-3"
          >
              Terug
          </button>
            <button
              onClick={onSubmit}
              className="px-4 py-2 text-sm text-white bg-primary rounded-full hover:bg-[#16B471]">
              Opslaan
            </button>
          </div>
        </>
      )}
    </div>
  );
}
