import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { axiosAuth } from '../../../utils/axiosInterceptor';

interface Customer {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    created_at: string;
    updated_at: string;
    active: number;
    // Other properties...
  }

export default function Customer() {
    const [customer, setCustomer] = useState<Customer | null>(null);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + '/administrator/customers/' + id)
      .then((response) => {
        console.log(response.data.customer);
        setCustomer(response.data.customer);
      });
  }, []);
  
  function deactivateCustomer() {
    if (!customer) return; // Check if customer is null or undefined
    axiosAuth
      .put(process.env.REACT_APP_BACKEND_URL + '/administrator/customers/' + customer.id + '/deactivate')
      .then(() => {
        setCustomer(null); // Reset customer state after deactivation
      });
      window.location.reload();
  }
  
  function anonymizeCustomer() {
    if (!customer) return; // Check if customer is null or undefined
    axiosAuth
      .put(process.env.REACT_APP_BACKEND_URL + '/administrator/customers/' + customer.id + '/anonymize')
      .then(() => {
        setCustomer(null); // Reset customer state after anonymization
      });
      window.location.reload();
  }

  function formatDate(dateTimeString: string) {
    const dateTime = new Date(dateTimeString);
    return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
  }


  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2 mb-5">
        <p className="text-black text-bold text-3xl mr-4">Gebuiker</p>
        <div className='flex flex-wrap gap-2'>
            <button
            onClick={() => deactivateCustomer()} // Handle null value for customer
            className="bg-primary rounded-full px-4 py-2 text-white hover:bg-[#16B471] mr-3"
            >
            Deactivate
            </button>
            <button
            onClick={() => anonymizeCustomer()} // Handle null value for customer
            className="bg-primary rounded-full px-4 py-2 text-white hover:bg-[#16B471] mr-3"
            >
            Anonimiseren
            </button>
        </div>
      </div>
      {customer ? ( // Check if customer is not null before rendering
        <div className="mx-auto bg-white shadow-lg rounded-lg w-full">
        <div className="p-3 overflow-auto">
            <div className="flex flex-col md:grid md:grid-cols-2 gap-4 mt-2">
                <div className="h-8 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                  <span className='mr-2 text-gray-500'>Voornaam:</span> {customer.firstname}
                </div>
                <div className="h-8 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                  <span className='mr-2 text-gray-500'>Achternaam:</span> {customer.lastname}
                </div>
                <div className="h-8 col-span-2 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                  <span className='mr-2 text-gray-500'>Email:</span> {customer.email}
                </div>
                <div className="h-8 col-span-2 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                  <span className='mr-2 text-gray-500'>Telefoon:</span> {customer.phone}
                </div>
                <div className="h-8 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                  <span className='mr-2 text-gray-500'>Id:</span> {customer.id}
                </div>
                <div className="h-8 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                <span className='mr-2 text-gray-500'>Actief:</span> {customer.active === 1 ? 'Yes' : 'No'}
                </div>
                <div className="h-8 col-span-2 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                <span className='mr-2 text-gray-500'>Gemaakt op:</span> {formatDate(customer.created_at)}
                </div>
                <div className="h-8 col-span-2 bg-gray-200 rounded pl-3 flex items-center overflow-hidden">
                <span className='mr-2 text-gray-500'>Laatst geupdate op:</span> {formatDate(customer.updated_at)}
                </div>
            </div>
        </div>
    </div>
      ) : (
        <div>Loading...</div>
      )}

    <div className='mt-8'>
        <button
            onClick={() => navigate(-1)}
            className="bg-primary rounded-full px-4 py-2 text-white hover:bg-[#16B471] mr-3"
        >
            Terug
        </button>
    </div>

    </div>
    
  );
}